import styled from 'styled-components';

const whiteColor = '#FFF';
const lightBlueColor = '#3B69C3';
const orangeColor = '#FE971F';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 12px 0 12px;

    @media (min-width: 0) and (max-width: 344px) {
        padding: 0 5px 0 5px;
    }
`;


const SearchStyles = {
    SearchWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        gap: 5px;
    `,
    SearchInputWrapper: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        border-radius: 16px;
        background: ${props => props.colorTheme.cardColor};
    `,
    SearchIcon: styled.img`
        width: 32px;
        height: 32px;
        margin-left: 4px;
    `,
    SearchInput: styled.input`
        width: 100%;
        height: 100%;
        margin-left: 6px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 1.17px;
        color: ${props => props.colorTheme.descriptionColor};
        background: transparent;
        border: none;
        outline: none;

        &::placeholder {
            color: #686868;
        }
    `,
    CartWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 51px;
        cursor: pointer;
    `,
    CartCircle: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        border-radius: 16px;
        background: ${props => props.colorTheme.cardColor};

        &::after {
            display: ${props => props.cartCount > 0 ? 'flex' : 'none'};
            content: '${props => props.cartCount > 0 ? props.cartCount : ''}';
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -8px;
            right: -8px;
            color: #FFF;
            font-family: "SF Pro Display", sans-serif;
            font-size: 10px;
            font-weight: 900;
            background: #E92D2D;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }
    `,
    CartIcon: styled.img`
        width: 24px;
        height: 24px;
    `,
}

const BestItemStyles = {
    PromoWrapper: styled.img`
        width: 259px;
        height: 324px;
        border-radius: 24px;
    `,
    BestItemWrapper: styled.div`
        position: relative;
        width: 259px;
        height: 324px;
        border-radius: 24px;
    `,
    BestItemImage: styled.img`
        width: 100%;
        height: 100%;
        border-radius: 24px;
        cursor: pointer;
        object-fit: cover;
    `,
    BestItemOverlay: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 0 0 24px 24px;
        background: rgba(0, 0, 0, 0.56);
        display: flex;
        flex-direction: column;
        padding: 2px 16px 2px 16px;
    `,
    BestItemTitle: styled.div`
        margin-top: 4px;
        display: flex;
        align-items: center;
    `,
    BestItemPrice: styled.span`
        color: #FFF;
        font-family: "Rubik", sans-serif;
        font-size: 22px;
        font-weight: 700;
        margin-right: 10px;
    `,
    BestItemDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        background: ${orangeColor};
    `,
    BestItemDiscount: styled.span`
        color: #FFF;
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
    `,
    BestItemTagsWrapper: styled.div`
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    BestItemTag: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        background: ${lightBlueColor};
    `
}

const ItemStyles = {
    PromoWrapper: styled.img`
        margin-top: 20px;
        width: 168px;
        height: 320px;
        border-radius: 20px;
    `,
    ItemWrapper: styled.div`
        margin-top: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 168px;
        height: 320px;
        background-color: ${props => props.cardColor};
        border-radius: 20px;
    `,
    ItemImage: styled.img`
        width: 100%;
        height: 170px;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
        object-fit: cover;
    `,
    ItemContent: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        padding: 0 5px 0 5px;
    `,
    ItemPriceWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    ItemPrice: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-right: 13px;
    `,
    ItemDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        background: ${orangeColor};
    `,
    ItemDiscount: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
    `,
    ItemTitle: styled.span`
        margin-top: 8px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
    `,
    ItemTagsWrapper: styled.div`
        position: absolute;
        bottom: 33px;
        left: 5px;
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    ItemTag: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        background: ${lightBlueColor};
    `,
    ItemEditionWrapper: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 20px 20px;
        background: #435FFF;
    `,
    ItemEdition: styled.span`
        color: ${whiteColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
    `,
}

const CategoryStyles = {
    CategoryBgWrapper: styled.div`
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 18px;
        border-radius: 20px;
        background-color: ${props => props.bgColor};
    `,
    CategoryWrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    CategoryTitleWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    CategoryIcon: styled.img`
        width: 24px;
        height: 24px;
        margin-right: 8px;
    `,
    CategoryTitle: styled.h2`
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 700;
        background: ${props => props.colorTheme.descriptionColor};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `,
}

const TabStyles = {
    TabWrapper: styled.div`
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 26px;
        border-radius: 6px;
        border: 1px solid ${props => props.colorTheme.tabBorderColor};
    `,
    TabItem: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 22px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 6px;
        ${props => props.isActive ? 'background: #435FFF;' : ''}
        cursor: pointer;
        transition: background 0.3s ease;
    `,
    TabItemTitle: styled.span`
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 500;
        ${props => props.isActive ? 'color: #FFF;' : `color: ${props.colorTheme.descriptionColor};`}
        transition: color 0.3s ease;
    `,
}

const SubscriptionsStyles = {
    SubscriptionsWrapper: styled.div`
        margin-top: 20px;
        width: 100%;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        flex-direction: column;
        padding-bottom: 18px;
    `,
    SubscriptionsTitle: styled.h2`
        margin-top: 18px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    SubscriptionsContent: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    `,
    SubscriptionsItem: styled.div`
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        height: 78px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        cursor: pointer;
    `,
    SubscriptionsItemIcon: styled.img`
        width: 78px;
        height: 78px;
        border-radius: 10px;
        object-fit: cover;
    `,
    SubscriptionsItemContent: styled.div`
        display: flex;
        flex-direction: column;
        margin-left: 25px;
    `,
    SubscriptionsItemPrice: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    SubscriptionsItemTitle: styled.span`
        margin-top: 5px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
}

const ItemInfoStyles = {
    ItemInfoPreviewWrapper: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
    `,
    ItemInfoPreview: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,
    ItemInfoWrapper: styled.div`
        margin-top: 375px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;
    `,
    ItemInfoMainWrapper: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
    ItemInfoMainContent: styled.div`
        width: 100%;
        padding-top: 12px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    ItemInfoMainFavouriteWrapper: styled.div`
        width: 180px;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding-left: 10px;
        border-radius: 12px;
        background: ${props => props.isActive ? '#FF3E3E' : props.colorTheme.backgroundColor};
        cursor: pointer;
    `,
    ItemInfoMainFavouriteText: styled.span`
        color: ${props => props.isActive ? whiteColor : props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 500;
    `,
    ItemInfoMainTitle: styled.h2`
        margin-top: 10px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0.56px;
    `,
    ItemInfoMainPriceWrapper: styled.div`
        margin-top: 6px;
        display: flex;
        align-items: center;
    `,
    ItemInfoMainPrice: styled.h1`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 32px;
        font-weight: 700;
    `,
    ItemInfoMainDiscountWrapper: styled.div`
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 8px 2px 8px;
        border-radius: 4px;
        background: ${orangeColor};
    `,
    ItemInfoMainDiscount: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
    `,
    ItemInfoMainSubtitle: styled.h3`
        margin-top: 6px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    ItemInfoMainEditionWrapper: styled.div`
        margin-top: 12px;
        display: flex;
        align-items: center;
        gap: 11px;
    `,
    ItemInfoMainEditionButton: styled.div`
        max-width: 150px;
        min-height: 72px;
        padding: 8px 22px;
        border-radius: 15px;
        border: 2px solid #C9C9C9;
        ${props => props.isActive ? 'border: 2px solid #3B69C3;' : 'border: 2px solid #C9C9C9;'}
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: border 0.2s ease-in;
    `,
    ItemInfoMainEditionTitle: styled.h3`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.28px;
        text-align: center;
    `,
    ItemInfoMainEditionPrice: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    `,
    ItemInfoDescriptionWrapper: styled.div`
        margin-top: 10px;
    `,
    ItemInfoDescriptionContent: styled.div`
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    ItemInfoDescription: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailWrapper: styled.div`
        margin-top: 10px;
    `,
    ItemInfoDetailContent: styled.div`
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        flex-direction: column;
    `,
    ItemInfoDetailPlatformWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
    `,
    ItemInfoDetailPlatformTitle: styled.span`
        color: #717171;
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailPlatformContentWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    ItemInfoDetailPlatformContent: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 2px 8px 2px 8px;
        border-radius: 4px;
        background: ${lightBlueColor};
    `,
    ItemInfoDetailLanguageWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
    `,
    ItemInfoDetailLanguageTitle: styled.span`
        color: #717171;
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailLanguageContent: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `,
    ItemInfoDetailReleaseDateWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
    `,
    ItemInfoDetailReleaseDateTitle: styled.span`
        color: #717171;
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailReleaseDateContent: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `
}

const CartStyles = {
    CartWrapper: styled.div`
        margin-top: 10px;
        margin-bottom: 180px;
        display: flex;
        flex-direction: column;
    `,
    CartTitle: styled.h2`
        margin-bottom: 5px;
        font-family: "Rubik", sans-serif;
        font-size: 28px;
        font-weight: 700;
        background: ${props => props.colorTheme.descriptionColor};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `,
    CartItemWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        min-height: 60px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cartItemBgColor};
    `,
    CartItemLeftWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    CartItemLeftImage: styled.img`
        width: 70px;
        height: 70px;
        border-radius: 10px;
        object-fit: cover;
    `,
    CartItemLeftContent: styled.div`
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
    `,
    CartItemLeftPriceWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 8px;
    `,
    CartItemLeftPrice: styled.span`
        color: ${props => props.colorTheme.cartItemPriceColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 700;
    `,
    CartItemLeftDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        background: ${orangeColor};
    `,
    CartItemLeftDiscount: styled.span`
        color: #FFF;
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
    `,
    CartItemLeftTitle: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.28px;
        margin-top: 2px;
    `,
    CartItemLeftEdition: styled.span`
        color: ${props => props.colorTheme.helpTextColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
        margin-top: 2px;
    `,
    CartItemRightWrapper: styled.div`
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 10px;
        background: ${whiteColor};
    `,
    CartItemRightIcon: styled.img`
        width: 32px;
        height: 32px;
    `,
    CartTotalWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
    `,
    CartTotalTitle: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.4px;
    `,
    CartTotalPrice: styled.span`
        font-family: "Rubik", sans-serif;
        font-size: 28px;
        font-weight: 700;
        color: #435FFF;
    `,
    CartOrderWrapper: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        padding-bottom: 14px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    CartOrderTitle: styled.h3`
        margin-top: 14px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.4px;
    `,
    CartOrderHelpTextWrapper: styled.div`
        margin-top: 8px;
    `,
    CartOrderHelpText: styled.p`
        color: #717171;
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 400;
    `,
    CartOrderAccountTitle: styled.span`
        margin-top: 14px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
    CartOrderAccountInput: styled.input`
        margin-top: 10px;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        outline: none;
        padding-left: 10px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
    `,
    CartOrderRadioWrapper: styled.div`
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    CartOrderRadioCircle: styled.div`
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #D9D9D9;
        cursor: pointer;
    `,
    CartOrderRadio: styled.div`
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${props => props.isActive ? '#435FFF' : '#E9ECFE'};
        transition: background 0.5s ease;
    `,
    CartOrderRadioTitle: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: ${props => props.fontSize ? props.fontSize : '14px'};
        font-weight: 400;
        letter-spacing: 0.28px;
    `,
    CartPromoWrapper: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        padding-bottom: ${props => props.isActive ? '20px' : '12px'};
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    CartPromoInputWrapper: styled.div`
        margin-top: 10px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    CartPromoInput: styled.input`
        width: 100%;
        border-radius: 10px;
        height: 40px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        outline: none;
        padding-left: 10px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 18px;
        font-weight: 400;
    `,
    CartPromoInputButton: styled.button`
        height: 40px;
        padding: 0 10px;
        background: #435FFF;
        border-radius: 10px;
        color: ${whiteColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    `,
    CartPromoHelpText: styled.span`
        margin-top: 4px;
        color: ${props => props.colorTheme.helpTextColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
    `,
    CartPromoActiveText: styled.span`
        margin-top: 12px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
    `,

    CartTwoFaWrapper: styled.div`
        margin-top: 10px;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 14px;
    `,
    CartTwoFaContent: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    CartTwoFaIcon: styled.img`
        width: 30px;
        height: 30px;
        border-radius: 8px;
    `,
    CartTwoFaTitle: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 400;
    `,
    CartTwoFaArrow: styled.img`
        width: 18px;
        height: 18px;
    `
}

const SearchPageStyles = {
    SearchWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    SearchInputWrapper: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        background: ${props => props.colorTheme.cardColor};
    `,
    SearchIcon: styled.img`
        width: 48px;
        height: 48px;
        margin-left: 4px;
    `,
    SearchInput: styled.input`
        width: 100%;
        height: 100%;
        margin-left: 6px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.17px;
        color: ${props => props.colorTheme.descriptionColor};
        background: transparent;
        border: none;
        outline: none;

        &::placeholder {
            color: #686868;
        }
    `,
    SearchItemNotFound: styled.span`
        margin-top: 20px;
        color: ${props => props.colorTheme.subscriptionBgColor};
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 500;
    `,
    SearchItemSuggestionsTitle: styled.h3`
        margin-top: 40px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
    SearchItemWrapper: styled.div`
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
    `,
    SearchItem: styled.div`
        width: 48%;
        margin-bottom: 10px;
    `,
}

const PersonStyles = {
    PersonTitle: styled.h1`
        margin-top: 10px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    PersonDataWrapper: styled.div`
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    PersonDataTitle: styled.h3`
        margin-top: 10px;
        margin-bottom: 5px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
    PersonDataInput: styled.input`
        margin-top: 10px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        height: 40px;
        width: 100%;
        outline: none;
        padding-left: 10px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 18px;
        font-weight: 400;
    `,
    PersonHelpText: styled.span`
        margin-top: 10px;
        color: ${props => props.colorTheme.helpTextColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 17px;
    `,
    PersonDeviceTitle: styled.h2`
        margin-top: 18px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
    PersonDeviceWrapper: styled.div`
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 100%;
    `,
    PersonDeviceItem: styled.div`
        width: 100%;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
        box-shadow: ${props => props.isActive ? '0px 0px 4px 4px rgba(67, 95, 255, 0.40);' : '0px 0px 4px 4px rgba(82, 85, 103, 0.27);'};
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    `,
    PersonDeviceItemTitle: styled.h3`
        color: ${props => props.isActive ? '#435FFF' : props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    PersonLinkWrapper: styled.div`
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 14px;
    `,
    PersonLinkContent: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    PersonLinkIcon: styled.img`
        width: 40px;
        height: 40px;
        border-radius: 8px;
    `,
    PersonLinkTitle: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    PersonLinkArrow: styled.img`
        width: 24px;
        height: 24px;
    `
}

const PersonLinkInfoStyles = {
    PersonLinkInfoTitle: styled.h1`
        margin-top: 12px;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 18px;
    `,
    PersonLinkInfoWrapper: styled.div`
        width: 100%;
        border-radius: 20px;
        background: #435FFF;
        padding: 12px 10px;
        display: flex;
        align-items: center;
        gap: 18px;
    `,
    PersonLinkInfoIcon: styled.img`
        width: 48px;
        height: 48px;
    `,
    PersonLinkContent: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    PersonLinkContentText: styled.p`
        color: #FFF;
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%; /* 16px */
        letter-spacing: 0.32px;
        white-space: pre-wrap;
    `
}

const ActiveSubscriptionStyles = {
    ActiveSubscriptionWrapper: styled.div`
        margin-top: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
        width: 100%;
    `,
    ActiveSubscriptionItems: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    `,
    ActiveSubscriptionItemWrapper: styled.div`
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        background: ${props => props.colorTheme.subscriptionBgColor};
        outline: ${props => props.isActive ? '3px solid #435FFF;' : 'none'}
    `,
    ActiveSubscriptionItemIcon: styled.img`
        width: 70px;
        height: 70px;
        border-radius: 10px;
    `,
    ActiveSubscriptionItemTitle: styled.h3`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 500;
    `,
    ActiveSubscriptionAlert: styled.div`
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border-radius: 8px;
        background: #435FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    ActiveSubscriptionAlertTitle: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 500;
    `,
    ActiveSubscriptionAlertInfo: styled.span`
        color: ${whiteColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 700;
    `,
    ActiveSubscriptionButton: styled.div`
        margin-top: 10px;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        background: #435FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `,
    ActiveSubscriptionButtonTitle: styled.span`
        color: ${whiteColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
}

const TwoFactorStyles = {
    TwoFactorInfoWrapper: styled.div`
        width: 100%;
        padding: 8px 10px 14px 10px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        flex-direction: column;
        gap: 15px;
    `,
    TwoFactorInfoHeader: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
    `,
    TwoFactorInfoHeaderTitle: styled.h3`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.32px;
    `,
    TwoFactorInfoContent: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    TwoFactorInfoContentText: styled.p`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "SF Pro Display", sans-serif;
        font-size: 12px;
        font-weight: 400;
    `,
    TwoFactorInfoContentTextBold: styled.b`
        font-weight: 700;
    `,
    TwoFactorItems: styled.div`
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    `,
    TwoFactorItemWrapper: styled.div`
        width: 100%;
        height: 50px;
        padding: 0 10px;
        border-radius: 12px;
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    TwoFactorItemText: styled.span`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 500;
        -webkit-text-decoration-line: ${props => props.isActive ? 'none' : 'line-through'};
        text-decoration-line: ${props => props.isActive ? 'none' : 'line-through'};
    `,
    TwoFactorItemAction: styled.div`
        color: #435FFF;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-decoration-line: underline;
    `,
}

const BuyHistoryStyles = {
    BuyHistoryItemWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;
        padding-right: 10px;
        width: 100%;
        min-height: 60px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cartItemBgColor};
    `,
    BuyHistoryItemTime: styled.span`
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        color: ${props => props.colorTheme.descriptionColor};
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 500;
        white-space: pre-wrap;
    `
}

const FiltersPageStyles = {
    FiltersWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    `,
    FiltersTitle: styled.h2`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: Rubik, sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    FiltersCloseBtn: styled.div`
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
    `,
    FiltersCloseIcon: styled.img`
        width: 28px;
        height: 28px;
    `,
    FiltersItemsWrapper: styled.div`
        margin-top: 17px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 28px;
    `,
    FiltersItemWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 2px;
    `,
    FiltersItemTitle: styled.h3`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: Rubik, sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    FiltersOptionsWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    `,
    FiltersSelect: styled.select`
        margin-top: 10px;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
        color: ${props => props.colorTheme.descriptionColor};
        font-family: Rubik, sans-serif;
        font-size: 16px;
        font-weight: 400;
        border: none;
        user-select: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 10px;
        outline: none;
    `
}


const PromoPageStyles = {
    PromoHeaderWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 18px;
    `,
    PromoHeaderTitle: styled.h2`
        color: ${props => props.colorTheme.descriptionColor};
        font-family: Rubik, sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    PromoHeaderFilters: styled.div`
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
    `,
    PromoHeaderFilterIcon: styled.img`
        width: 28px;
        height: 28px;
    `,
    PromoItemsWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
    `,
}

export {
    Container,
    SearchStyles,
    BestItemStyles,
    CategoryStyles,
    ItemStyles,
    TabStyles,
    SubscriptionsStyles,
    ItemInfoStyles,
    CartStyles,
    SearchPageStyles,
    PersonStyles,
    PersonLinkInfoStyles,
    ActiveSubscriptionStyles,
    TwoFactorStyles,
    BuyHistoryStyles,
    FiltersPageStyles,
    PromoPageStyles
};
