import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '../styles/main.css';
import PersonLinkInfo from '../components/PersonLinkInfo/PersonLinkInfo';
import config from '../config';
import Radio from '../components/Cart/Radio';
import CartItem from '../components/Cart/CartItem';
import { get, post } from '../lib/api';
import { updateUserData } from '../redux-state/reducers/UserInfo';


const Favourite = () => {

    const [isDiscount, setIsDiscount] = useState(false);
    const [displayItems, setDisplayItems] = useState([]);

    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const user = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    const backButtonHandler = () => {
        navigate('/person');
    }

    mainButton.color = "#435FFF";
    mainButton.textColor = "#FFFFFF";
    mainButton.enable();

    const goToCatalog = () => {
        navigate('/');
    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);

    useEffect(() => {
        post('/items/getEditionsById', {token: user.token}, {
            edition_ids: user.favourites
        }).then(data => {
            isDiscount ? setDisplayItems(data.filter(item => item.discount && item.discount > 0)) : setDisplayItems(data);
        })

    }, [user, isDiscount]);


    useEffect(() => {
        mainButton.text = "Перейти в каталог"
        mainButton.onClick(goToCatalog);
        mainButton.show();
        return () => {
            mainButton.offClick(goToCatalog);
            mainButton.hide();
        }
    }, []);

    const deleteItem = (item) => {
        post('/items/switchFavourite', {token: user.token, edition_id: item.id}).then((res) => {
            dispatch(updateUserData({...user, favourites: res.favourites}));
        })
    }

    const goToEdition = (item) => {
        if ('sub_type' in item) {
            navigate('/subscription/' + item.id);
        } else {
            navigate('/item/' + item.id);
        }
    }

    return (
        <>
            <PersonLinkInfo
                title="Избранное"
                icon={config.STATIC_BASE_URL + '/star.svg'}
                textList={[
                    'Вы получите уведомление \nот бота, если цена на товар \nснизится',
                ]}
                theme={theme}
            />
            <Container>
                <Radio theme={theme} active={isDiscount} handleActive={() => setIsDiscount(!isDiscount)} title="Только со скидкой" fontSize={"16px"} marginTop={"16px"} />
                {
                    displayItems.length > 0 ? displayItems.map((item, index) => <CartItem key={index} item={item} theme={theme} deleteItem={deleteItem} marginTop={index === 0 ? '16px' : undefined} onClick={() => goToEdition(item)} />) : null
                }
            </Container>
        </>
    );
}

export default Favourite;
