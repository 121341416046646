
const CartIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_16_5)">
                <path
                    d="M11.5 12H12.5V20H11.5V12ZM17 20H18V12H17V20ZM6.00001 20H7.00001V12H6.00001V20ZM23.976 8L22.132 21.831C21.966 23.067 20.901 24 19.653 24H4.33801C3.08901 24 2.02501 23.067 1.85901 21.83L0.0160065 8H3.05901C3.55801 3.507 7.37501 0 12 0C16.625 0 20.442 3.507 20.941 8H23.976ZM4.07001 8H19.931C19.436 4.06 16.072 1 12 1C7.92801 1 4.56401 4.06 4.06901 8H4.07001ZM22.834 9H1.15801L2.85101 21.698C2.94901 22.44 3.58801 23 4.33701 23H19.651C20.4 23 21.04 22.44 21.139 21.698L22.831 9H22.834Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_16_5">
                    <rect width="24" height="24" fill={color}/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default CartIcon;