import React from 'react';
import { BestItemStyles } from '../../styles/main.css';
import config from '../../config';

const { PromoWrapper } = BestItemStyles;

const Promo = ({ image }) => {

    return (
        <PromoWrapper src={config.BASE_URL + image} alt="promo" />
    );
}

export default Promo;
