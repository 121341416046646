import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Item from './pages/Item';
import Cart from './pages/Cart';
import Search from './pages/Search';
import Subscription from './pages/Subscription';
import Person from './pages/Person';
import ActiveSubscriptions from './pages/ActiveSubscriptions';
import TwoFA from './pages/TwoFA';
import Favourite from './pages/Favourite';
import BuyHistory from './pages/BuyHistory';
import ActiveSubscriptionDate from './pages/ActiveSubscriptionDate';
import Filters from './pages/Filters';
import Promo from './pages/Promo';

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/item/:editionId" element={<Item/>}/>
            <Route path="/subscription/:subscriptionId" element={<Subscription/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/search" element={<Search/>}/>
            <Route path="/person" element={<Person/>}/>
            <Route path="/activeSubscriptions" element={<ActiveSubscriptions/>}/>
            <Route path="/activeSubscriptionDate/:subscriptionId" element={<ActiveSubscriptionDate/>}/>
            <Route path="/twoFactor" element={<TwoFA/>}/>
            <Route path="/favourite" element={<Favourite/>}/>
            <Route path="/buyHistory" element={<BuyHistory/>}/>
            <Route path="/filters" element={<Filters/>}/>
            <Route path="/promo/:promoId" element={<Promo/>}/>
        </Routes>
    );
}

export default AppRoutes;
