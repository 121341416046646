import React, { useCallback, useEffect, useState } from 'react';
import { Container, PersonStyles } from '../../styles/main.css';
import Input from './Input';
import { put } from '../../lib/api';
import { updateUserData } from '../../redux-state/reducers/UserInfo';
import { useDispatch, useSelector } from 'react-redux';

const {  PersonDataWrapper, PersonDataTitle, PersonHelpText } = PersonStyles;
const PersonData = ({ theme, user }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    console.log(email);

    const dispatch = useDispatch();


    useEffect(() => {
        if (user) {
            setEmail(user.accountEmail || '');
            setPassword(user.accountPassword || '');
        }
    }, []);

    const updateUser = () => {
        put('/users/updateData', { token: user.token }, {
            account_email: email,
            account_password: password
        }).then(data => {
            dispatch(updateUserData({...user, accountEmail: data.user.account_email, accountPassword: data.user.account_password}));
        })
    }

    useEffect(() => {
        // updateUser();
        window.addEventListener('unload', updateUser);

        return () => window.removeEventListener('unload', updateUser);

    }, [email, password, user]);


    return (
        <PersonDataWrapper colorTheme={theme}>
            <Container>
                <PersonDataTitle colorTheme={theme}>Данные аккаунта</PersonDataTitle>
                <Input placeholder="Введите Email" theme={theme} value={email} setValue={setEmail} onBlur={updateUser} type="email" tabIndex="0" />
                <Input placeholder="Введите пароль" theme={theme} value={password} setValue={setPassword} onBlur={updateUser} type="text" tabIndex="1" />
                <PersonHelpText colorTheme={theme}>Бот запомнит данные и автоматически использует при оформлении заказа</PersonHelpText>
            </Container>
        </PersonDataWrapper>
    )
}

export default PersonData;