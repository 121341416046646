import React from 'react';
import config from '../../config';
import { BuyHistoryStyles, CartStyles } from '../../styles/main.css';
import { useNavigate } from 'react-router-dom';


const { CartItemWrapper, CartItemLeftWrapper, CartItemLeftImage, CartItemLeftContent, CartItemLeftPriceWrapper, CartItemLeftPrice, CartItemLeftDiscountWrapper, CartItemLeftDiscount, CartItemLeftTitle, CartItemLeftEdition } = CartStyles;
const { BuyHistoryItemWrapper, BuyHistoryItemTime } = BuyHistoryStyles;


const BuyHistoryItem = ({ item, theme, marginTop }) => {

    const navigate = useNavigate();

    const goToItem = () => {
        if ('sub_type' in item) {
            navigate('/subscription/' + item.id);
        } else {
            navigate('/item/' + item.id);
        }
    }

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const subTypeText = (subType) => {
        if (subType === 'ps_plus') {
            return 'PS Plus'
        } else if (subType === 'ea_play') {
            return 'EA Play'
        } else if (subType === 'xbox') {
            return 'Xbox'
        }
    }

    return (
        <>
            <BuyHistoryItemWrapper colorTheme={theme} onClick={goToItem} style={{ marginTop: marginTop ? marginTop : '0' }}>
                <CartItemLeftWrapper>
                    <CartItemLeftImage src={'sub_type' in item ? config.BASE_URL + item.image : item.image} alt={item.title} />
                    <CartItemLeftContent colorTheme={theme}>
                        <CartItemLeftPriceWrapper colorTheme={theme}>
                            <CartItemLeftPrice colorTheme={theme}>{formatPrice(item.price)} ₽</CartItemLeftPrice>
                            <CartItemLeftDiscountWrapper colorTheme={theme}>
                                <CartItemLeftDiscount colorTheme={theme}>-{item.discount}%</CartItemLeftDiscount>
                            </CartItemLeftDiscountWrapper>
                        </CartItemLeftPriceWrapper>
                        <CartItemLeftTitle colorTheme={theme}>{item.title}</CartItemLeftTitle>
                        {
                            'sub_type' in item ? <CartItemLeftEdition colorTheme={theme}>{subTypeText(item.sub_type)}</CartItemLeftEdition> : <CartItemLeftEdition colorTheme={theme}>{item.edition}</CartItemLeftEdition>
                        }
                    </CartItemLeftContent>
                </CartItemLeftWrapper>
                <BuyHistoryItemTime colorTheme={theme}>{`${item.date}\n${item.time}`}</BuyHistoryItemTime>
            </BuyHistoryItemWrapper>
        </>
    )

}

export default BuyHistoryItem