import React from 'react';
import { TwoFactorStyles } from '../../styles/main.css';
import InfoIcon from '../Icons/InfoIcon';


const { TwoFactorInfoWrapper, TwoFactorInfoHeader, TwoFactorInfoHeaderTitle, TwoFactorInfoContent, TwoFactorInfoContentText, TwoFactorInfoContentTextBold } = TwoFactorStyles;

const Instruction = ({ theme }) => {
    return (
        <>
            <TwoFactorInfoWrapper colorTheme={theme}>
                <TwoFactorInfoHeader>
                    <InfoIcon color={theme.descriptionColor} />
                    <TwoFactorInfoHeaderTitle colorTheme={theme}>
                        Как найти 2FA коды?
                    </TwoFactorInfoHeaderTitle>
                </TwoFactorInfoHeader>
                <TwoFactorInfoContent>
                    <TwoFactorInfoContentText colorTheme={theme}>
                        <TwoFactorInfoContentTextBold>На PS5:</TwoFactorInfoContentTextBold> нажмите сверху справа на Settings > Users and Accounts > Account > Security > 2-Step Verification > Backup Codes.
                    </TwoFactorInfoContentText>
                    <TwoFactorInfoContentText colorTheme={theme}>
                        <TwoFactorInfoContentTextBold>На PS4:</TwoFactorInfoContentTextBold> нажмите сверху справа на Settings > Account Management > Account Information > Security > 2-Step Verification > Backup Codes.
                    </TwoFactorInfoContentText>
                </TwoFactorInfoContent>
            </TwoFactorInfoWrapper>
        </>
    )
}

export default Instruction