import React from 'react';
import { ActiveSubscriptionStyles } from '../../styles/main.css';


const { ActiveSubscriptionAlert, ActiveSubscriptionAlertTitle, ActiveSubscriptionAlertInfo } = ActiveSubscriptionStyles;

const Alert = ({ date }) => {

    return (
        <>
            <ActiveSubscriptionAlert>
                <ActiveSubscriptionAlertTitle>Активен до:</ActiveSubscriptionAlertTitle>
                <ActiveSubscriptionAlertInfo>{date}</ActiveSubscriptionAlertInfo>
            </ActiveSubscriptionAlert>
        </>
    )
}

export default Alert