import React, { useEffect, useState } from 'react';
import {Container, ItemInfoStyles} from '../../styles/main.css';
import config from '../../config'
import {Swiper, SwiperSlide} from "swiper/react";
import StarIcon from '../Icons/StarIcon';
import { post } from '../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../redux-state/reducers/UserInfo';

const { ItemInfoPreviewWrapper, ItemInfoPreview, ItemInfoWrapper, ItemInfoMainWrapper, ItemInfoMainContent, ItemInfoMainFavouriteWrapper, ItemInfoMainFavouriteText, ItemInfoMainTitle,
    ItemInfoMainPriceWrapper, ItemInfoMainPrice, ItemInfoMainDiscountWrapper, ItemInfoMainDiscount,
    ItemInfoMainSubtitle, ItemInfoMainEditionWrapper, ItemInfoMainEditionButton, ItemInfoMainEditionTitle,
    ItemInfoMainEditionPrice, ItemInfoDescriptionWrapper, ItemInfoDescriptionContent, ItemInfoDescription,
    ItemInfoDetailWrapper, ItemInfoDetailContent, ItemInfoDetailPlatformWrapper, ItemInfoDetailPlatformTitle,
    ItemInfoDetailPlatformContentWrapper, ItemInfoDetailPlatformContent, ItemInfoDetailLanguageWrapper,
    ItemInfoDetailLanguageTitle, ItemInfoDetailLanguageContent, ItemInfoDetailReleaseDateWrapper,
    ItemInfoDetailReleaseDateTitle, ItemInfoDetailReleaseDateContent } = ItemInfoStyles;

const ItemInfo = ({ edition, editions, setCurrentEdition, theme }) => {

    const [isFavorite, setIsFavorite] = useState(false);
    const user = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const changeFavorite = () => {
        post('/items/switchFavourite', {token: user.token, edition_id: edition.id}).then((res) => {
            dispatch(updateUserData({...user, favourites: res.favourites}));
        })
    }

    const changeEdition = (id) => {
        const newEdition = editions.find(ed => ed.id === id);
        setCurrentEdition(newEdition);
    }
    const formatDescription = (description) => {
        return description.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
        });
    }

    useEffect(() => {
        user.favourites.includes(edition.id) ? setIsFavorite(true) : setIsFavorite(false);
    }, [user]);

    return (
        <>
            <ItemInfoPreviewWrapper>
                <ItemInfoPreview src={edition.image} alt={'Item Preview'}/>
            </ItemInfoPreviewWrapper>
            <ItemInfoWrapper colorTheme={theme}>
                <ItemInfoMainWrapper colorTheme={theme}>
                    <ItemInfoMainContent colorTheme={theme}>
                        <Container>
                            <ItemInfoMainFavouriteWrapper colorTheme={theme} onClick={changeFavorite} isActive={isFavorite}>
                                <StarIcon color={isFavorite ? '#FFF' : theme.descriptionColor} />
                                <ItemInfoMainFavouriteText colorTheme={theme} isActive={isFavorite}>{isFavorite ? 'Добавлено' : 'В избранное'}</ItemInfoMainFavouriteText>
                            </ItemInfoMainFavouriteWrapper>
                            <ItemInfoMainTitle colorTheme={theme}>{edition.title}</ItemInfoMainTitle>
                            <ItemInfoMainPriceWrapper colorTheme={theme}>
                                <ItemInfoMainPrice colorTheme={theme}>{formatPrice(edition.price)} ₽</ItemInfoMainPrice>
                                {edition.discount > 0 &&
                                    <ItemInfoMainDiscountWrapper colorTheme={theme}>
                                        <ItemInfoMainDiscount colorTheme={theme}>-{edition.discount}%</ItemInfoMainDiscount>
                                    </ItemInfoMainDiscountWrapper>
                                }
                            </ItemInfoMainPriceWrapper>
                            <ItemInfoMainSubtitle colorTheme={theme}>Издания</ItemInfoMainSubtitle>
                            <ItemInfoMainEditionWrapper>
                                {
                                    editions && editions.length > 2 &&
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={2.5}
                                    >
                                        {
                                            editions && editions.map((ed) => (
                                                <SwiperSlide>
                                                    <ItemInfoMainEditionButton colorTheme={theme} key={ed.id} isActive={ed.id === edition.id} onClick={() => changeEdition(ed.id)}>
                                                        <ItemInfoMainEditionTitle colorTheme={theme}>{ed.edition}</ItemInfoMainEditionTitle>
                                                        <ItemInfoMainEditionPrice colorTheme={theme}>{formatPrice(ed.price)} ₽</ItemInfoMainEditionPrice>
                                                    </ItemInfoMainEditionButton>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                }
                                {
                                    editions && editions.length <= 2 &&
                                    editions && editions.map((ed) => (
                                        <ItemInfoMainEditionButton colorTheme={theme} key={ed.id} isActive={ed.id === edition.id} onClick={() => changeEdition(ed.id)}>
                                            <ItemInfoMainEditionTitle colorTheme={theme}>{ed.edition}</ItemInfoMainEditionTitle>
                                            <ItemInfoMainEditionPrice colorTheme={theme}>{formatPrice(ed.price)} ₽</ItemInfoMainEditionPrice>
                                        </ItemInfoMainEditionButton>
                                    ))
                                }
                            </ItemInfoMainEditionWrapper>
                        </Container>
                    </ItemInfoMainContent>
                </ItemInfoMainWrapper>
                <ItemInfoDescriptionWrapper colorTheme={theme}>
                    <ItemInfoDescriptionContent colorTheme={theme}>
                        <Container>
                            <ItemInfoDescription colorTheme={theme}>{formatDescription(edition.description)}</ItemInfoDescription>
                        </Container>
                    </ItemInfoDescriptionContent>
                </ItemInfoDescriptionWrapper>
                <ItemInfoDetailWrapper colorTheme={theme}>
                    <ItemInfoDetailContent colorTheme={theme}>
                        <Container>
                            <ItemInfoDetailPlatformWrapper colorTheme={theme}>
                                <ItemInfoDetailPlatformTitle colorTheme={theme}>Платформа</ItemInfoDetailPlatformTitle>
                                <ItemInfoDetailPlatformContentWrapper colorTheme={theme}>
                                    {edition.platforms.map((platform, index) => (
                                        <ItemInfoDetailPlatformContent colorTheme={theme} key={index}>{platform}</ItemInfoDetailPlatformContent>
                                    ))}
                                </ItemInfoDetailPlatformContentWrapper>
                            </ItemInfoDetailPlatformWrapper>
                            <ItemInfoDetailLanguageWrapper colorTheme={theme}>
                                <ItemInfoDetailLanguageTitle colorTheme={theme}>Язык</ItemInfoDetailLanguageTitle>
                                <ItemInfoDetailLanguageContent colorTheme={theme}>{edition.language}</ItemInfoDetailLanguageContent>
                            </ItemInfoDetailLanguageWrapper>
                            <ItemInfoDetailReleaseDateWrapper colorTheme={theme}>
                                <ItemInfoDetailReleaseDateTitle colorTheme={theme}>Дата релиза</ItemInfoDetailReleaseDateTitle>
                                <ItemInfoDetailReleaseDateContent colorTheme={theme}>{edition.release_date}</ItemInfoDetailReleaseDateContent>
                            </ItemInfoDetailReleaseDateWrapper>
                        </Container>
                    </ItemInfoDetailContent>
                </ItemInfoDetailWrapper>
            </ItemInfoWrapper>
        </>
    );
}

export default ItemInfo;
