import React from 'react';
import { ActiveSubscriptionStyles } from '../../styles/main.css';
import { useNavigate } from 'react-router-dom';


const { ActiveSubscriptionItemWrapper, ActiveSubscriptionItemIcon, ActiveSubscriptionItemTitle } = ActiveSubscriptionStyles;

const ActiveSubscriptionItem = ({ theme, icon ,title, isActive, subId, marginTop }) => {

    const navigate = useNavigate();

    const goToLink = () => {
        navigate(`/activeSubscriptionDate/${subId}`);
    }

    return (
        <>
            <ActiveSubscriptionItemWrapper colorTheme={theme} onClick={goToLink} isActive={isActive} style={{ marginTop: marginTop ? marginTop : '0' }}>
                <ActiveSubscriptionItemIcon src={icon} alt="icon" />
                <ActiveSubscriptionItemTitle colorTheme={theme}>{title}</ActiveSubscriptionItemTitle>
            </ActiveSubscriptionItemWrapper>
        </>
    )
}

export default ActiveSubscriptionItem