import React, {useEffect, useState} from 'react';
import { Container } from '../styles/main.css'
import Search from '../components/Search/Search';
import BestItems from '../components/BestItems/BestItems';
import { useTheme } from '../context/ThemeContext';
import Category from "../components/Category/Category";
import config from "../config";
import Subscriptions from "../components/Subscriptions/Subscriptions";
import {useSelector} from "react-redux";
import {get} from "../lib/api";
import {useNavigate} from "react-router-dom";

const Main = () => {
    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const [ sellItems, setSellItems ] = useState();
    const [ categories, setCategories ] = useState();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;
    const totalCart = cart.items.length + cart.subscriptions.length;

    const mainButton = tg.MainButton;
    mainButton.color = "#435FFF";
    mainButton.textColor = "#FFFFFF";
    mainButton.enable();

    const goToCart = () => {
        navigate('/cart');
    }

    const goToPerson = () => {
        navigate('/person');
    }

    const goToSearch = () => {
        navigate('/search');
    }

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const editionId = params.get('edition');
        const utm = params.get('utm');
        if (editionId && utm) {
            navigate(`/item/${editionId}?utm=${utm}`, {state: {close: true}});
        } else if (editionId) {
            navigate(`/item/${editionId}`, {state: {close: true}});
        }
    }, [])

    useEffect(() => {
        if (totalCart > 0) {
            mainButton.text = "Перейти в корзину"
            mainButton.onClick(goToCart);
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            mainButton.hide();
        }
    }, [cart]);

    useEffect(() => {
        get('/items/getSellItems', {token: user.token}).then((res) => {
            setSellItems(res.items);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        get('/items/getCategories', {token: user.token}).then((res) => {
            setCategories(res.categories);
            console.log(res.categories);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <div style={{ marginBottom: '50px' }}>
            <Container>
                <Search colorTheme={theme} cartCount={totalCart} goToCart={goToCart} goToSearch={goToSearch} goToPerson={goToPerson} />
            </Container>
            <BestItems />
            <Category
                icon={`${config.STATIC_BASE_URL}/fire_red.svg`}
                title="Лидеры продаж"
                items={sellItems}
                theme={theme}
                index={0}
                promos={[]}
            />
            <Subscriptions theme={theme} />
            {
                categories && categories.map((category, index) => (
                    <Category
                        key={index}
                        title={category.name}
                        items={category.items}
                        promos={category.sub_categories}
                        theme={theme}
                        index={index}
                    />
                ))
            }
        </div>
    );
}

export default Main;
