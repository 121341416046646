import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, ActiveSubscriptionStyles } from '../styles/main.css';
import PersonLinkInfo from '../components/PersonLinkInfo/PersonLinkInfo';
import config from '../config';
import ActiveSubscriptionItem from '../components/ActiveSubscriptionItem/ActiveSubscriptionItem';
import Alert from '../components/ActiveSubscriptionItem/Alert';
import { get } from '../lib/api';


const { ActiveSubscriptionWrapper, ActiveSubscriptionItems } = ActiveSubscriptionStyles;

const ActiveSubscriptions = () => {

    const [subscriptions, setSubscriptions] = useState([]);
    const [PSPlusDate, setPSPlusDate] = useState(null);
    const [EADate, setEADate] = useState(null);

    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const user = useSelector(state => state.userInfo);

    const isActive = (subId) => {
        return !!user.activeSubs.find(sub => sub.id === subId);

    }

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    mainButton.hide();

    const backButtonHandler = () => {
        navigate('/person');
    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);

    useEffect(() => {
        get('/users/subs', {token: user.token}).then(data => {
            setSubscriptions(data.subs);
        })
    }, []);

    useEffect(() => {
        if (user.activeSubs.find(sub => sub.id === 2)) {
            setPSPlusDate(user.activeSubs.find(sub => sub.id === 2).date)
        }
        if (user.activeSubs.find(sub => sub.id === 5)) {
            setPSPlusDate(user.activeSubs.find(sub => sub.id === 5).date)
        }
        if (user.activeSubs.find(sub => sub.id === 8)) {
            console.log(user.activeSubs.find(sub => sub.id === 8).date)
            setPSPlusDate(user.activeSubs.find(sub => sub.id === 8).date)
        }
        if (user.activeSubs.find(sub => sub.id === 11)) {
            setEADate(user.activeSubs.find(sub => sub.id === 11).date)
        }
    }, [user]);


    return (
        <>
            <PersonLinkInfo
                title="Активная подписка"
                icon={config.STATIC_BASE_URL + '/crown.svg'}
                textList={[
                    'Выберите подписку и укажите дату окончания.',
                    'Бот скроет доступные по ней игры из каталога и напомнит о необходимости продления.'
                ]}
                theme={theme}
            />
            <ActiveSubscriptionWrapper colorTheme={theme}>
                <Container>
                    <ActiveSubscriptionItems>
                        {
                            subscriptions.filter(sub => sub.type === 'ps_plus').map(sub => <ActiveSubscriptionItem
                                key={sub.id}
                                theme={theme}
                                icon={config.BASE_URL + sub.image}
                                title={sub.name}
                                isActive={isActive(sub.id)}
                                subId={sub.id}
                            />)
                        }
                        {
                            PSPlusDate ? <Alert date={PSPlusDate} /> : null
                        }
                        {
                            subscriptions.filter(sub => sub.type === 'ea_play').map(sub => <ActiveSubscriptionItem
                                key={sub.id}
                                theme={theme}
                                icon={config.BASE_URL + sub.image}
                                title={sub.name}
                                isActive={isActive(sub.id)}
                                subId={sub.id}
                                marginTop='8px'
                            />)
                        }
                        {
                            EADate ? <Alert date={EADate} /> : null
                        }
                    </ActiveSubscriptionItems>
                </Container>
            </ActiveSubscriptionWrapper>
        </>
    );
}

export default ActiveSubscriptions;
