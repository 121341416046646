import React, {useEffect, useState} from 'react';
import {CartStyles, Container} from '../../styles/main.css';
import config from '../../config'
import {useDispatch} from "react-redux";
import {deleteSubscription, deleteItem} from "../../redux-state/reducers/UserInfo";
import CartItem from './CartItem';

const { CartWrapper, CartTitle, CartItemWrapper, CartItemLeftWrapper, CartItemLeftImage, CartItemLeftContent, CartItemLeftPrice, CartItemLeftTitle, CartItemLeftEdition, CartItemRightWrapper, CartItemRightIcon, CartTotalWrapper, CartTotalTitle, CartTotalPrice } = CartStyles;

const Header = ({ cart, totalAmount, theme }) => {

    const [cartItems, setCartItems] = useState([]);

    const dispatch = useDispatch();

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }


    const deleteItemCart = (item) => {
        if ('sub_type' in item) {
            dispatch(deleteSubscription(item));
        } else {
            dispatch(deleteItem(item));
        }
    }

    useEffect(() => {
        setCartItems(cart.items.concat(cart.subscriptions));
    }, [cart]);

    return (
        <>
            <Container>
                <CartTitle colorTheme={theme}>Корзина</CartTitle>
                {
                    cartItems.map((item, index) => {
                        return (
                            <CartItem key={index} theme={theme} item={item} deleteItem={deleteItemCart} />
                        )
                    })
                }
                <CartTotalWrapper colorTheme={theme}>
                    <CartTotalTitle colorTheme={theme}>Итого:</CartTotalTitle>
                    <CartTotalPrice colorTheme={theme}>{formatPrice(totalAmount)} ₽</CartTotalPrice>
                </CartTotalWrapper>
            </Container>
        </>
    );
}

export default Header;
