import {createGlobalStyle} from 'styled-components';

import RubikRegular from './../fonts/Rubik-Regular.ttf';
import RubikMedium from './../fonts/Rubik-Medium.ttf';
import RubikBold from './../fonts/Rubik-Bold.ttf';
import SFProDisplayRegular from './../fonts/SF-Pro-Display-Regular.ttf';
import SFProDisplayMedium from './../fonts/SF-Pro-Display-Medium.ttf';
import SFProDisplayBold from './../fonts/SF-Pro-Display-Bold.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${RubikRegular}) format('truetype'),
  }

  @font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(${RubikMedium}) format('truetype'),
  }
  
  @font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${RubikBold}) format('truetype'),
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${SFProDisplayRegular}) format('truetype'),
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(${SFProDisplayMedium}) format('truetype'),
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${SFProDisplayBold}) format('truetype'),
  }
`;