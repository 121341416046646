import React from 'react';
import { PersonStyles } from '../../styles/main.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import ArrowIcon from '../Icons/ArrowIcon';

const { PersonLinkWrapper, PersonLinkContent, PersonLinkIcon, PersonLinkTitle, PersonLinkArrow } = PersonStyles;
const Link = ({theme, icon, title, link, marginTop}) => {

    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    const goToLink = () => {
        if (link.startsWith('/')) {
            navigate(link);
        } else {
            tg.disableClosingConfirmation();
            tg.openTelegramLink(link);
        }
    }

    return (
        <PersonLinkWrapper colorTheme={theme} onClick={goToLink} style={{ marginTop: marginTop }}>
            <PersonLinkContent colorTheme={theme}>
                <PersonLinkIcon src={icon} alt="icon" />
                <PersonLinkTitle colorTheme={theme}>{title}</PersonLinkTitle>
            </PersonLinkContent>
            <ArrowIcon color={theme.descriptionColor} width={24} />
        </PersonLinkWrapper>
    )
}

export default Link;