import {createContext, useContext, useEffect, useState} from 'react';
import { darkTheme, lightTheme } from '../styles/themes';
import { useSelector } from 'react-redux';

const ThemeContext = createContext();

export const useTheme = () => {
    return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
    const user = useSelector(state => state.userInfo);
    const [theme, setTheme] = useState(lightTheme);

    useEffect(() => {
        setTheme(user.theme === 'dark' ? darkTheme : lightTheme);
    }, [user.theme]);

    return (
        <ThemeContext.Provider value={{ theme }}>
            {children}
        </ThemeContext.Provider>
    );
};
