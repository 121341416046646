import React from 'react';
import { ItemStyles } from '../styles/main.css';
import config from '../config';

const { PromoWrapper } = ItemStyles;

const Promo = ({ image }) => {

    return (
        <PromoWrapper src={config.BASE_URL + image} alt="promo" />
    );
}

export default Promo;
