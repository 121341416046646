import React, {useEffect, useState} from 'react';
import { Container } from '../styles/main.css'
import { useTheme } from '../context/ThemeContext';
import config from "../config";
import {useDispatch, useSelector} from "react-redux";
import {get, post} from "../lib/api";
import ItemInfo from "../components/ItemInfo/ItemInfo";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {addItem} from "../redux-state/reducers/UserInfo";
import { handleMetrika } from '../lib/metrika';

const Item = () => {
    const [ editions, setEditions ] = useState([]);
    const [ currentEdition, setCurrentEdition ] = useState();
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;
    const { editionId } = useParams();
    const { state } = useLocation();

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!state || !state.close) {
        backButton.show();
    }
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });

    const mainButton = tg.MainButton;
    mainButton.color = "#435FFF";
    mainButton.textColor = "#FFFFFF";
    mainButton.enable();

    const goToCart = () => {
        navigate('/cart');
    }

    const addToCart = () => {
        dispatch(addItem(currentEdition));
        handleMetrika('addCart');
    }

    useEffect(() => {
        post('/users/updateCart', {token: user.token}, {cart: cart}).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [cart]);

    useEffect(() => {
        if (currentEdition && cart.items.find(item => item.id === currentEdition.id)) {
            mainButton.text = "Перейти в корзину"
            mainButton.onClick(goToCart);
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            if (currentEdition) {
                mainButton.text = "Добавить в корзину"
                mainButton.onClick(addToCart);
                mainButton.show();
                return () => {
                    mainButton.offClick(addToCart);
                }
            }
        }
    }, [cart, currentEdition]);

    useEffect(() => {
        get('/items/getEdition', {token: user.token, edition_id: editionId}).then((res) => {
            setEditions(res.editions);
            setCurrentEdition(res.editions.find(edition => edition.id === parseInt(editionId)));
            res.editions.find(ed => ed.platforms.includes('Steam')) && handleMetrika('steamOpen')
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            {
                currentEdition && <ItemInfo theme={theme} edition={currentEdition} editions={editions} setCurrentEdition={setCurrentEdition} />
            }
        </>
    );
}

export default Item;
