import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, SearchPageStyles, SearchStyles } from '../styles/main.css';
import config from '../config';
import { get } from '../lib/api';
import FiltersIcon from '../components/Icons/FiltersIcon';
import Item from '../components/Item';


const {SearchWrapper, SearchIcon, SearchInputWrapper, SearchInput, CartWrapper, CartCircle} = SearchStyles;
const {SearchItemWrapper, SearchItem, SearchItemNotFound, SearchItemSuggestionsTitle} = SearchPageStyles;

const Search = () => {
    const {theme} = useTheme();
    const user = useSelector(state => state.userInfo);
    const {state} = useLocation();

    const [searchValue, setSearchValue] = useState(state?.searchValue || '');
    const [searchItems, setSearchItems] = useState([]);
    const [randomItems, setRandomItems] = useState([]);

    const [platform, setPlatform] = useState(state?.platform);
    const [category, setCategory] = useState(state?.category);
    const [price, setPrice] = useState(state?.price);

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        window.history.replaceState(null, null, `?q=${e.target.value}`);
        window.ym('97203825', 'hit');
    }

    useEffect(() => {
        get('/items/getRandom', {token: user.token}).then((res) => {
            setRandomItems(res.items);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        if (searchValue.length > 0) {
            get('/items/search', {token: user.token, q: searchValue}).then((res) => {
                let filterItems = res.items;
                if (platform) {
                    filterItems = filterItems.filter((item) => item.platforms.includes(platform));
                }
                if (category) {
                    filterItems = filterItems.filter((item) => item.categories.includes(category));
                }
                if (price) {
                    filterItems = filterItems.filter((item) => item.price <= price);
                }
                setSearchItems(filterItems);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setSearchItems([]);
        }
    }, [searchValue]);

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    backButton.show();
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });

    const goToFilters = () => {
        navigate('/filters', {
            state: {
                prevPage: '/search',
                searchValue: searchValue,
                platform: platform,
                category: category,
                price: price
            }
        });
    }

    const mainButton = tg.MainButton;
    mainButton.hide();

    return (
        <Container>
            <SearchWrapper>
                <SearchInputWrapper colorTheme={theme}>
                    <SearchIcon src={`${config.STATIC_BASE_URL}/search.svg`} alt="search"/>
                    <SearchInput type="text" placeholder="Найти игру или подписку" colorTheme={theme}
                                 value={searchValue} onChange={handleSearch} autoFocus={true}/>
                </SearchInputWrapper>
                <CartWrapper onClick={goToFilters}>
                    <CartCircle cartCount={0} colorTheme={theme}>
                        <FiltersIcon color={theme.descriptionColor}/>
                    </CartCircle>
                </CartWrapper>
            </SearchWrapper>
            {
                searchItems.length === 0 && searchValue.length > 0 &&
                <>
                    <SearchItemNotFound colorTheme={theme}>Позиция не найдена</SearchItemNotFound>
                    <SearchItemSuggestionsTitle colorTheme={theme}>Смотрите также</SearchItemSuggestionsTitle>
                    <SearchItemWrapper>
                        {
                            randomItems.map((item, index) => {
                                return (
                                    <SearchItem key={index} onClick={() => navigate(`/item/${item.id}`)}>
                                        <Item theme={theme} image={item.image} price={item.price} discount={item.discount}
                                              title={item.title} tags={item.tags} edition={item.edition}/>
                                    </SearchItem>
                                )
                            })
                        }
                    </SearchItemWrapper>
                </>
            }
            <SearchItemWrapper>
                {searchItems.map((item, index) => {
                    return (
                        <SearchItem key={index} onClick={() => navigate(`/item/${item.id}`)}>
                            <Item theme={theme} image={item.image} price={item.price} discount={item.discount}
                                  title={item.title} tags={item.tags} edition={item.edition}/>
                        </SearchItem>
                    )
                })}
            </SearchItemWrapper>
        </Container>
    );
}

export default Search;
