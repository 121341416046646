import React, {useEffect, useState} from 'react';
import {CartStyles, Container} from '../../styles/main.css';
import Tab from "./Tab";
import Radio from "./Radio";
import TwoFaLink from './TwoFaLink';
import { get } from '../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDiscount, setUserPromoCode } from '../../redux-state/reducers/UserInfo';

const { CartOrderWrapper, CartOrderTitle, CartOrderHelpTextWrapper, CartOrderHelpText, CartOrderAccountTitle, CartOrderAccountInput, CartPromoWrapper, CartPromoActiveText, CartPromoInputWrapper, CartPromoInput, CartPromoInputButton, CartPromoHelpText } = CartStyles;

const Details = ({ setEnable, theme, accountEmailValue, accountPasswordValue, checkoutEmailValue, setAccountEmailValue, setAccountPasswordValue, setCheckoutEmailValue, rememberData, setRememberData, discount, setDiscount, promoValue, setPromoValue, activeTab, setActiveTab }) => {

    const [helpText, setHelpText] = useState('');
    const [emailForCheckActive, setEmailForCheckActive] = useState(false);

    const [promoActive, setPromoActive] = useState(false);
    const [promoHelpText, setPromoHelpText] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    const user = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeTab === 0) {
            setHelpText('У меня есть турецкий аккаунт, оформить на него\n(Если вы оформляете не PlayStation или XBOX, пожалуйста, перейдите во вкладку "Нет аккаунта".)');
        } else {
            setHelpText('Бот бесплатно создаст для вас турецкий аккаунт, оформит на него заказ и передаст вам логин с паролем');
        }
    }, [activeTab])

    useEffect(() => {
        if (activeTab === 0) {
            if (accountEmailValue && accountPasswordValue) {
                if (emailForCheckActive) {
                    setEnable(true);
                } else if (checkoutEmailValue) {
                    setEnable(true);
                } else {
                    setEnable(false);
                }
            } else {
                setEnable(false);
            }
        } else {
            if (checkoutEmailValue) {
                setEnable(true);
            } else {
                setEnable(false);
            }
        }
    }, [accountEmailValue, accountPasswordValue, checkoutEmailValue, emailForCheckActive, activeTab])

    const handleEmailForCheck = () => {
        setEmailForCheckActive(!emailForCheckActive);
        !emailForCheckActive && setCheckoutEmailValue(accountEmailValue);
    }

    const handleRememberData = () => {
        setRememberData(!rememberData);
    }

    useEffect(() => {
        discount && discount > 0 ? setDisableButton(true) : setDisableButton(false);
    }, [promoValue, discount]);

    const activatePromo = () => {
        get('/promo/getPromo', {token: user.token, promo: promoValue }).then((res) => {
            if (res.success) {
                setPromoHelpText('Промокод на скидку ' + res.discount + '% активирован');
                setDiscount(res.discount);
                dispatch(setUserDiscount(res.discount));
                dispatch(setUserPromoCode(promoValue));
                setPromoValue('');
                setPromoActive(false);
            } else {
                setPromoHelpText('Промокод не найден');
            }
        })
    }

    const handlePromo = () => {
        setPromoActive(!promoActive);
    }

    return (
        <>
            <CartOrderWrapper colorTheme={theme}>
                <Container>
                    <CartOrderTitle colorTheme={theme}>Куда оформить заказ</CartOrderTitle>
                    <Tab activeTab={activeTab} setActiveTab={setActiveTab} />
                    <CartOrderHelpTextWrapper>
                        {
                            helpText && helpText.split('\n').map((text, index) => <CartOrderHelpText key={index} colorTheme={theme}>{text}</CartOrderHelpText>)
                        }
                    </CartOrderHelpTextWrapper>
                    {/*<CartOrderHelpText colorTheme={theme}>{helpText}</CartOrderHelpText>*/}
                    {
                        activeTab === 0 &&
                        <>
                            <CartOrderAccountTitle colorTheme={theme}>Данные аккаунта</CartOrderAccountTitle>
                            <CartOrderAccountInput colorTheme={theme} type="text" placeholder="Введите Email (обязательно)" value={accountEmailValue} onChange={(e) => setAccountEmailValue(e.target.value)} />
                            <CartOrderAccountInput colorTheme={theme} type="password" placeholder="Введите пароль (обязательно)" value={accountPasswordValue} onChange={(e) => setAccountPasswordValue(e.target.value)} />
                            <TwoFaLink theme={theme} />
                            <Radio theme={theme} title="Email для чека такой же, как логин" active={emailForCheckActive} handleActive={handleEmailForCheck} />
                        </>
                    }
                    {
                        (activeTab === 0 && !emailForCheckActive || activeTab === 1) && <CartOrderAccountInput colorTheme={theme} type="text" placeholder="Введите Email для чека" value={checkoutEmailValue} onChange={(e) => setCheckoutEmailValue(e.target.value)} />
                    }
                    <Radio theme={theme} title="Запомнить данные для следующих заказов" active={rememberData} handleActive={handleRememberData} />
                </Container>
            </CartOrderWrapper>
            <CartPromoWrapper colorTheme={theme} isActive={promoActive}>
                <Container>
                    {
                        discount > 0 ?
                            <CartPromoActiveText colorTheme={theme}>Промокод на скидку {discount}% активирован</CartPromoActiveText>
                            :
                            <Radio theme={theme} title="У меня есть промокод" active={promoActive} handleActive={handlePromo} />
                    }
                    {
                        promoActive &&
                        <>
                            <CartPromoInputWrapper colorTheme={theme}>
                                <CartPromoInput colorTheme={theme} type="text" placeholder="Промокод" value={promoValue} onChange={(e) => setPromoValue(e.target.value)} />
                                {
                                    !promoValue || disableButton ?
                                        <></>
                                        :
                                        <CartPromoInputButton onClick={activatePromo} colorTheme={theme}>Активировать</CartPromoInputButton>
                                }
                            </CartPromoInputWrapper>
                            {
                                promoHelpText &&
                                <CartPromoHelpText colorTheme={theme}>{promoHelpText}</CartPromoHelpText>
                            }
                        </>
                    }
                </Container>
            </CartPromoWrapper>
        </>
    );
}

export default Details;
