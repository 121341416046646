import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '../styles/main.css';
import PersonLinkInfo from '../components/PersonLinkInfo/PersonLinkInfo';
import config from '../config';
import BuyHistoryItem from '../components/BuyHistory/BuyHistoryItem';
import { get } from '../lib/api';


const BuyHistory = () => {

    const [items, setItems] = useState([]);

    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const user = useSelector(state => state.userInfo);

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;

    const backButtonHandler = () => {
        navigate(-1);
    }

    mainButton.color = "#435FFF";
    mainButton.textColor = "#FFFFFF";
    mainButton.enable();

    const goToCatalog = () => {
        navigate('/');
    }

    useEffect(() => {
        mainButton.text = "Перейти в каталог"
        mainButton.onClick(goToCatalog);
        mainButton.show();
        return () => {
            mainButton.offClick(goToCatalog);
            mainButton.hide();
        }
    }, []);

    useEffect(() => {
        get('/users/buyHistory', {token: user.token}).then(data => {
            setItems(data.result);

        })
    }, []);

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);

    return (
        <>
            <PersonLinkInfo
                title="История покупок"
                icon={config.STATIC_BASE_URL + '/percent.svg'}
                textList={[
                    'Здесь вы найдете список ваших \nпоследних покупок',
                ]}
                theme={theme}
            />
            <Container>
                {
                    items.length > 0 ? items.map((item, index) => <BuyHistoryItem key={index} item={item} theme={theme} marginTop={index === 0 ? '30px' : '8px'} />) : null
                }
            </Container>
        </>
    );
}

export default BuyHistory;
