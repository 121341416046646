import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    token: null,
    name: null,
    theme: null,
    accountEmail: null,
    accountPassword: null,
    checkoutEmail: null,
    cart: {
        items: [],
        subscriptions: []
    },
    device: null,
    activeSubs: [],
    favourites: [],
    discount: 0,
    promoCode: null
};


const infoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.token = action.payload.token;
            state.name = action.payload.name;
            state.theme = action.payload.theme;
            state.checkoutEmail = action.payload.checkoutEmail;
            state.accountEmail = action.payload.accountEmail;
            state.accountPassword = action.payload.accountPassword;
            state.device = action.payload.device;
            state.activeSubs = action.payload.activeSubs;
            state.favourites = action.payload.favourites;
        },
        updateUserData: (state, action) => {
            state.token = action.payload.token;
            state.name = action.payload.name;
            state.theme = action.payload.theme;
            state.checkoutEmail = action.payload.checkoutEmail;
            state.accountEmail = action.payload.accountEmail;
            state.accountPassword = action.payload.accountPassword;
            state.device = action.payload.device;
            state.activeSubs = action.payload.activeSubs;
            state.favourites = action.payload.favourites;
        },
        addItem: (state, action) => {
            state.cart.items.push(action.payload);
        },
        addSubscription: (state, action) => {
            state.cart.subscriptions.push(action.payload);
        },
        deleteItem: (state, action) => {
            state.cart.items = state.cart.items.filter(item => item.id !== action.payload.id);
        },
        deleteSubscription: (state, action) => {
            state.cart.subscriptions = state.cart.subscriptions.filter(subscription => subscription.id !== action.payload.id);
        },
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        clearCart: (state) => {
            state.cart = {
                items: [],
                subscriptions: []
            };
        },
        setUserDiscount: (state, action) => {
            state.discount = action.payload
        },
        setUserPromoCode: (state, action) => {
            state.promoCode = action.payload
        }
    }
});


export const { addUser, updateUserData, addItem, addSubscription, deleteItem, deleteSubscription, clearCart, setCart, setUserDiscount, setUserPromoCode } = infoSlice.actions;
export default infoSlice.reducer;
