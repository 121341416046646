import config from '../../config';
import React from 'react';
import { CartStyles } from '../../styles/main.css';
import { deleteItem, deleteSubscription } from '../../redux-state/reducers/UserInfo';
import { useDispatch } from 'react-redux';


const {
    CartItemWrapper,
    CartItemLeftWrapper,
    CartItemLeftImage,
    CartItemLeftContent,
    CartItemLeftPriceWrapper,
    CartItemLeftPrice,
    CartItemLeftDiscountWrapper,
    CartItemLeftDiscount,
    CartItemLeftTitle,
    CartItemLeftEdition,
    CartItemRightWrapper,
    CartItemRightIcon
} = CartStyles;

const CartItem = ({item, theme, deleteItem, marginTop, onClick}) => {

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    const subTypeText = (subType) => {
        if (subType === 'ps_plus') {
            return 'PS Plus'
        } else if (subType === 'ea_play') {
            return 'EA Play'
        } else if (subType === 'xbox') {
            return 'Xbox'
        }
    }

    return (
        <CartItemWrapper colorTheme={theme} style={{marginTop: marginTop ? marginTop : null}}>
            <CartItemLeftWrapper onClick={onClick ? onClick : null}>
                <CartItemLeftImage src={'sub_type' in item ? config.BASE_URL + item.image : item.image}
                                   alt={item.title}/>
                <CartItemLeftContent colorTheme={theme}>
                    <CartItemLeftPriceWrapper colorTheme={theme}>
                        <CartItemLeftPrice colorTheme={theme}>{formatPrice(item.price)} ₽</CartItemLeftPrice>
                        {
                            item.discount > 0 ?
                                <CartItemLeftDiscountWrapper colorTheme={theme}>
                                    <CartItemLeftDiscount colorTheme={theme}>-{item.discount}%</CartItemLeftDiscount>
                                </CartItemLeftDiscountWrapper>
                                :
                                null
                        }
                    </CartItemLeftPriceWrapper>
                    <CartItemLeftTitle colorTheme={theme}>{item.title}</CartItemLeftTitle>
                    {
                        'sub_type' in item ?
                            <CartItemLeftEdition colorTheme={theme}>{subTypeText(item.sub_type)}</CartItemLeftEdition> :
                            <CartItemLeftEdition colorTheme={theme}>{item.edition}</CartItemLeftEdition>
                    }
                </CartItemLeftContent>
            </CartItemLeftWrapper>
            <CartItemRightWrapper colorTheme={theme} onClick={() => deleteItem(item)}>
                <CartItemRightIcon src={config.STATIC_BASE_URL + '/basket.svg'} alt="Delete"/>
            </CartItemRightWrapper>
        </CartItemWrapper>
    )
}

export default CartItem