import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useDispatch, useSelector} from "react-redux";
import {get, post} from "../lib/api";
import {useNavigate, useParams} from "react-router-dom";
import {addSubscription} from "../redux-state/reducers/UserInfo";
import SubscriptionInfo from "../components/SubscriptionInfo/SubscriptionInfo";
import { handleMetrika } from '../lib/metrika';

const Subscription = () => {
    const [ subscriptions, setSubscriptions ] = useState([]);
    const [ currentSubscription, setCurrentSubscription ] = useState();
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;
    const { subscriptionId } = useParams();

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    backButton.show();
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });

    const mainButton = tg.MainButton;
    mainButton.color = "#435FFF";
    mainButton.textColor = "#FFFFFF";
    mainButton.enable();

    const goToCart = () => {
        navigate('/cart');
    }

    const addToCart = () => {
        dispatch(addSubscription(currentSubscription));
        handleMetrika('addCart');
    }

    useEffect(() => {
        post('/users/updateCart', {token: user.token}, {cart: cart}).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [cart]);

    useEffect(() => {
        if (currentSubscription && cart.subscriptions.find(sub => sub.id === currentSubscription.id)) {
            mainButton.text = "Перейти в корзину"
            mainButton.onClick(goToCart);
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            if (currentSubscription) {
                mainButton.text = "Добавить в корзину"
                mainButton.onClick(addToCart);
                mainButton.show();
                return () => {
                    mainButton.offClick(addToCart);
                }
            }
        }
    }, [cart, currentSubscription]);

    useEffect(() => {
        get('/items/getSubscription', {token: user.token, subscription_id: subscriptionId}).then((res) => {
            setSubscriptions(res.subscriptions);
            setCurrentSubscription(res.subscriptions.find(subscription => subscription.id === parseInt(subscriptionId)));
            handleMetrika('subscriptionOpen');
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            {
                currentSubscription && <SubscriptionInfo theme={theme} subscription={currentSubscription} subscriptions={subscriptions} setCurrentSubscription={setCurrentSubscription} />
            }
        </>
    );
}

export default Subscription;
