import React from 'react';
import { Container, PersonLinkInfoStyles } from '../../styles/main.css';

const { PersonLinkInfoTitle, PersonLinkInfoWrapper, PersonLinkInfoIcon, PersonLinkContent, PersonLinkContentText } = PersonLinkInfoStyles;
const PersonLinkInfo = ({ title, icon, textList, theme }) => {

    return (
        <Container>
            <PersonLinkInfoTitle colorTheme={theme}>{title}</PersonLinkInfoTitle>
            <PersonLinkInfoWrapper>
                <PersonLinkInfoIcon src={icon} alt="info icon" />
                <PersonLinkContent>
                    {
                        textList && textList.map((text, index) => {
                            return (
                                <PersonLinkContentText key={index}>{text}</PersonLinkContentText>
                            )
                        })
                    }
                </PersonLinkContent>
            </PersonLinkInfoWrapper>
        </Container>
    )
}

export default PersonLinkInfo