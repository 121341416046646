import React from 'react';
import {Container, ItemInfoStyles} from '../../styles/main.css';
import config from '../../config'
import {Swiper, SwiperSlide} from "swiper/react";

const { ItemInfoPreviewWrapper, ItemInfoPreview, ItemInfoWrapper, ItemInfoMainWrapper, ItemInfoMainContent, ItemInfoMainTitle,
    ItemInfoMainPriceWrapper, ItemInfoMainPrice,
    ItemInfoMainSubtitle, ItemInfoMainEditionWrapper, ItemInfoMainEditionButton, ItemInfoMainEditionTitle,
    ItemInfoMainEditionPrice, ItemInfoDescriptionWrapper, ItemInfoDescriptionContent, ItemInfoDescription,
    ItemInfoDetailWrapper, ItemInfoDetailContent, ItemInfoDetailPlatformWrapper, ItemInfoDetailPlatformTitle,
    ItemInfoDetailPlatformContentWrapper, ItemInfoDetailPlatformContent, ItemInfoDetailLanguageWrapper,
    ItemInfoDetailLanguageTitle, ItemInfoDetailLanguageContent, ItemInfoDetailReleaseDateWrapper,
    ItemInfoDetailReleaseDateTitle, ItemInfoDetailReleaseDateContent } = ItemInfoStyles;

const SubscriptionInfo = ({ subscription, subscriptions, setCurrentSubscription, theme }) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const formatDescription = (description) => {
        return description.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
        });
    }
    const changeSubscription = (id) => {
        const newSubscription = subscriptions.find(sub => sub.id === id);
        setCurrentSubscription(newSubscription);
    }
    const durationText = (duration) => {
        if (duration === 1) {
            return "1 месяц"
        } else if (duration > 1 && duration < 5) {
            return `${duration} месяца`
        } else {
            return `${duration} месяцев`
        }
    }
    return (
        <>
            <ItemInfoPreviewWrapper>
                <ItemInfoPreview src={`${config.BASE_URL}${subscription.image}`} alt={'Item Preview'}/>
            </ItemInfoPreviewWrapper>
            <ItemInfoWrapper colorTheme={theme}>
                <ItemInfoMainWrapper colorTheme={theme}>
                    <ItemInfoMainContent colorTheme={theme}>
                        <Container>
                            <ItemInfoMainTitle colorTheme={theme}>{subscription.title}</ItemInfoMainTitle>
                            <ItemInfoMainPriceWrapper colorTheme={theme}>
                                <ItemInfoMainPrice colorTheme={theme}>{formatPrice(subscription.price)} ₽</ItemInfoMainPrice>
                            </ItemInfoMainPriceWrapper>
                            <ItemInfoMainSubtitle colorTheme={theme}>Подписки</ItemInfoMainSubtitle>
                            <ItemInfoMainEditionWrapper colorTheme={theme}>
                                {
                                    subscriptions && subscriptions.length > 2 &&
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={2.5}
                                    >
                                        {
                                            subscriptions && subscriptions.map((sub) => (
                                                <SwiperSlide>
                                                    <ItemInfoMainEditionButton colorTheme={theme} key={sub.id} isActive={sub.id === subscription.id} onClick={() => changeSubscription(sub.id)}>
                                                        <ItemInfoMainEditionTitle colorTheme={theme}>{durationText(sub.duration)}</ItemInfoMainEditionTitle>
                                                        <ItemInfoMainEditionPrice colorTheme={theme}>{formatPrice(sub.price)} ₽</ItemInfoMainEditionPrice>
                                                    </ItemInfoMainEditionButton>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                }
                                {
                                    subscriptions && subscriptions.length <= 2 &&
                                    subscriptions && subscriptions.map((sub) => (
                                        <ItemInfoMainEditionButton colorTheme={theme} key={sub.id} isActive={sub.id === subscription.id} onClick={() => changeSubscription(sub.id)}>
                                            <ItemInfoMainEditionTitle colorTheme={theme}>{durationText(sub.duration)}</ItemInfoMainEditionTitle>
                                            <ItemInfoMainEditionPrice colorTheme={theme}>{formatPrice(sub.price)} ₽</ItemInfoMainEditionPrice>
                                        </ItemInfoMainEditionButton>
                                    ))
                                }
                            </ItemInfoMainEditionWrapper>
                        </Container>
                    </ItemInfoMainContent>
                </ItemInfoMainWrapper>
                <ItemInfoDescriptionWrapper colorTheme={theme}>
                    <ItemInfoDescriptionContent colorTheme={theme}>
                        <Container>
                            <ItemInfoDescription colorTheme={theme}>{formatDescription(subscription.description)}</ItemInfoDescription>
                        </Container>
                    </ItemInfoDescriptionContent>
                </ItemInfoDescriptionWrapper>
                <ItemInfoDetailWrapper colorTheme={theme}>
                    <ItemInfoDetailContent colorTheme={theme}>
                        <Container>
                            <ItemInfoDetailPlatformWrapper colorTheme={theme}>
                                <ItemInfoDetailPlatformTitle colorTheme={theme}>Платформа</ItemInfoDetailPlatformTitle>
                                <ItemInfoDetailPlatformContentWrapper colorTheme={theme}>
                                    {subscription.platforms.map((platform, index) => (
                                        <ItemInfoDetailPlatformContent colorTheme={theme} key={index}>{platform}</ItemInfoDetailPlatformContent>
                                    ))}
                                </ItemInfoDetailPlatformContentWrapper>
                            </ItemInfoDetailPlatformWrapper>
                            <ItemInfoDetailLanguageWrapper colorTheme={theme}>
                                <ItemInfoDetailLanguageTitle colorTheme={theme}>Язык</ItemInfoDetailLanguageTitle>
                                <ItemInfoDetailLanguageContent colorTheme={theme}>{subscription.language}</ItemInfoDetailLanguageContent>
                            </ItemInfoDetailLanguageWrapper>
                            <ItemInfoDetailReleaseDateWrapper colorTheme={theme}>
                                <ItemInfoDetailReleaseDateTitle colorTheme={theme}>Дата релиза</ItemInfoDetailReleaseDateTitle>
                                <ItemInfoDetailReleaseDateContent colorTheme={theme}>{subscription.release_date}</ItemInfoDetailReleaseDateContent>
                            </ItemInfoDetailReleaseDateWrapper>
                        </Container>
                    </ItemInfoDetailContent>
                </ItemInfoDetailWrapper>
            </ItemInfoWrapper>
        </>
    );
}

export default SubscriptionInfo;
