import React from 'react';
import { ItemStyles } from '../styles/main.css';

const { ItemWrapper, ItemImage, ItemContent, ItemPriceWrapper, ItemPrice, ItemDiscountWrapper, ItemDiscount, ItemTitle, ItemTagsWrapper, ItemTag, ItemEdition, ItemEditionWrapper } = ItemStyles;

const Item = ({ image, price, discount, title, tags, edition, theme, cardColor }) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const bgCardColor = cardColor ? cardColor : theme.cardColor

    return (
        <>
            <ItemWrapper cardColor={bgCardColor}>
                <ItemImage src={image} alt="item" />
                <ItemContent>
                    <ItemPriceWrapper>
                        <ItemPrice colorTheme={theme}>{formatPrice(price)} ₽</ItemPrice>
                        {
                            discount > 0 &&
                            <ItemDiscountWrapper>
                                <ItemDiscount>-{discount}%</ItemDiscount>
                            </ItemDiscountWrapper>
                        }
                    </ItemPriceWrapper>
                    <ItemTitle colorTheme={theme}>{title}</ItemTitle>
                    <ItemTagsWrapper>
                        {tags.map((tag, index) => {
                            return <ItemTag key={index}>{tag}</ItemTag>
                        })}
                    </ItemTagsWrapper>
                    <ItemEditionWrapper>
                        <ItemEdition>{edition}</ItemEdition>
                    </ItemEditionWrapper>
                </ItemContent>
            </ItemWrapper>
        </>
    );
}

export default Item;
