import React from 'react';
import { TabStyles } from '../../styles/main.css';
import {useTheme} from "../../context/ThemeContext";

const { TabWrapper, TabItem, TabItemTitle } = TabStyles;

const Tab = ({ activeTab, setActiveTab }) => {

    const { theme } = useTheme();
    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    return (
        <>
            <TabWrapper colorTheme={theme}>
                <TabItem onClick={() => handleTabClick(0)} isActive={activeTab === 0}>
                    <TabItemTitle colorTheme={theme} isActive={activeTab === 0}>1 месяц</TabItemTitle>
                </TabItem>
                <TabItem onClick={() => handleTabClick(1)} isActive={activeTab === 1}>
                    <TabItemTitle colorTheme={theme} isActive={activeTab === 1}>3 месяца</TabItemTitle>
                </TabItem>
                <TabItem onClick={() => handleTabClick(2)} isActive={activeTab === 2}>
                    <TabItemTitle colorTheme={theme} isActive={activeTab === 2}>12 месяцев</TabItemTitle>
                </TabItem>
            </TabWrapper>
        </>
    );
}

export default Tab;
