export const lightTheme = {
    backgroundColor: '#E7E7E7',
    descriptionColor: '#000',
    cardColor: '#fff',
    secondaryCardColor: '#E7E7E7',
    subscriptionBgColor: '#E7E7E7',
    tabBorderColor: '#C9C9C9',
    cartItemBgColor: '#D9D9D9',
    cartItemPriceColor: '#3B69C3',
    helpTextColor: '#717171',
};

export const darkTheme = {
    backgroundColor: '#1E1E1E',
    descriptionColor: '#fff',
    cardColor: '#2b2b2b',
    secondaryCardColor: '#1E1E1E',
    subscriptionBgColor: '#424242',
    tabBorderColor: '#424242',
    cartItemBgColor: '#424242',
    cartItemPriceColor: '#2CB5E7',
    helpTextColor: '#E7E7E7',
};
