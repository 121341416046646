import React, { useState } from 'react';
import { TwoFactorStyles } from '../../styles/main.css';
import { put } from '../../lib/api';


const { TwoFactorItemWrapper, TwoFactorItemText, TwoFactorItemAction } = TwoFactorStyles;

const TwoFAItem = ({ theme, codeId, code, isActive, user, setCodes }) => {

    const handleActive = () => {
        put('/users/twoFa', { token: user.token }, { id: codeId }).then(data => {
            setCodes(data.result);
        });
    }

    return (
        <>
            <TwoFactorItemWrapper colorTheme={theme}>
                <TwoFactorItemText colorTheme={theme} isActive={isActive}>
                    {code}
                </TwoFactorItemText>
                <TwoFactorItemAction onClick={handleActive}>
                    {
                        isActive ? 'Использовал' : 'Отменить'
                    }
                </TwoFactorItemAction>
            </TwoFactorItemWrapper>
        </>
    )
}

export default TwoFAItem