import React from 'react';
import { CartStyles } from '../../styles/main.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import ArrowIcon from '../Icons/ArrowIcon';

const { CartTwoFaWrapper, CartTwoFaContent, CartTwoFaIcon, CartTwoFaTitle, CartTwoFaArrow } = CartStyles;
const TwoFaLink = ({ theme }) => {

    const navigate = useNavigate();

    const goToLink = () => {
        navigate('/twoFactor')
    }

    return (
        <CartTwoFaWrapper colorTheme={theme} onClick={goToLink}>
            <CartTwoFaContent colorTheme={theme}>
                <CartTwoFaIcon src={config.STATIC_BASE_URL + '/2fa.svg'} alt="icon" />
                <CartTwoFaTitle colorTheme={theme}>2FA коды (для покупок PS)</CartTwoFaTitle>
            </CartTwoFaContent>
            <ArrowIcon color={theme.descriptionColor} width={18} />
        </CartTwoFaWrapper>
    )
}

export default TwoFaLink;
