import React, {useEffect, useState} from 'react';
import {Container, SubscriptionsStyles} from '../../styles/main.css';
import Tab from './Tab';
import config from '../../config';
import {get} from "../../lib/api";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const { SubscriptionsWrapper, SubscriptionsTitle, SubscriptionsContent, SubscriptionsItem, SubscriptionsItemIcon, SubscriptionsItemContent, SubscriptionsItemPrice, SubscriptionsItemTitle } = SubscriptionsStyles;

const Subscriptions = ({ theme }) => {

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const user = useSelector(state => state.userInfo);
    const [activeTab, setActiveTab] = useState(0);
    const [subscriptionsItems, setSubscriptionsItems] = useState();
    const [subscriptionsItemsEA, setSubscriptionsItemsEA] = useState();
    const [subscriptionsItemsXbox, setSubscriptionsItemsXbox] = useState();

    const navigate = useNavigate();

    const navigateToSubscription = (id) => {
        navigate(`/subscription/${id}`);
    }

    useEffect(() => {
        get('/items/getSubscriptions', {token: user.token}).then((res) => {
            setSubscriptionsItems(res.ps_sub);
            setSubscriptionsItemsEA(res.ea_sub);
            setSubscriptionsItemsXbox(res.xbox_sub);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            <SubscriptionsWrapper colorTheme={theme}>
                <Container>
                    <SubscriptionsTitle colorTheme={theme}>Подписки PS Plus</SubscriptionsTitle>
                    <Tab activeTab={activeTab} setActiveTab={setActiveTab} />
                    <SubscriptionsContent>
                        {subscriptionsItems && subscriptionsItems.map((item, index) => (
                            <SubscriptionsItem key={index} colorTheme={theme} activeTab={activeTab} onClick={() => navigateToSubscription(item.id)}>
                                <SubscriptionsItemIcon src={`${config.BASE_URL}${item.image}`} alt={item.title} />
                                <SubscriptionsItemContent>
                                    <SubscriptionsItemPrice colorTheme={theme}>{formatPrice(item.price[activeTab])} ₽</SubscriptionsItemPrice>
                                    <SubscriptionsItemTitle colorTheme={theme}>{item.title}</SubscriptionsItemTitle>
                                </SubscriptionsItemContent>
                            </SubscriptionsItem>
                        ))}
                    </SubscriptionsContent>
                    <SubscriptionsTitle colorTheme={theme}>Подписки EA Play</SubscriptionsTitle>
                    <SubscriptionsContent>
                        {subscriptionsItemsEA && subscriptionsItemsEA.map((item, index) => (
                            <SubscriptionsItem key={index} colorTheme={theme} onClick={() => navigateToSubscription(item.id)}>
                                <SubscriptionsItemIcon src={`${config.BASE_URL}${item.image}`} alt={item.title} />
                                <SubscriptionsItemContent>
                                    <SubscriptionsItemPrice colorTheme={theme}>{formatPrice(item.price)} ₽</SubscriptionsItemPrice>
                                    <SubscriptionsItemTitle colorTheme={theme}>{item.title}</SubscriptionsItemTitle>
                                </SubscriptionsItemContent>
                            </SubscriptionsItem>
                        ))}
                    </SubscriptionsContent>
                    <SubscriptionsTitle colorTheme={theme}>Подписки Xbox</SubscriptionsTitle>
                    <SubscriptionsContent>
                        {subscriptionsItemsXbox && subscriptionsItemsXbox.map((item, index) => (
                            <SubscriptionsItem key={index} colorTheme={theme} onClick={() => navigateToSubscription(item.id)}>
                                <SubscriptionsItemIcon src={`${config.BASE_URL}${item.image}`} alt={item.title} />
                                <SubscriptionsItemContent>
                                    <SubscriptionsItemPrice colorTheme={theme}>{formatPrice(item.price)} ₽</SubscriptionsItemPrice>
                                    <SubscriptionsItemTitle colorTheme={theme}>{item.title}</SubscriptionsItemTitle>
                                </SubscriptionsItemContent>
                            </SubscriptionsItem>
                        ))}
                    </SubscriptionsContent>
                </Container>
            </SubscriptionsWrapper>
        </>
    );
}

export default Subscriptions;
