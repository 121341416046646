import React from 'react';
import { SearchStyles } from '../../styles/main.css';
import config from '../../config'
import PersonIcon from '../Icons/PersonIcon';
import CartIcon from '../Icons/CartIcon';

const { SearchWrapper, SearchInputWrapper, SearchIcon, SearchInput, CartWrapper, CartCircle } = SearchStyles;

const Search = ({ colorTheme, cartCount, goToCart, goToSearch, goToPerson }) => {
    return (
        <>
            <SearchWrapper>
                <SearchInputWrapper onClick={goToSearch} colorTheme={colorTheme}>
                    <SearchIcon src={`${config.STATIC_BASE_URL}/search.svg`} alt="search" />
                    <SearchInput type="text" placeholder="Найти игру или подписку" colorTheme={colorTheme} />
                </SearchInputWrapper>
                <CartWrapper onClick={goToPerson}>
                    <CartCircle cartCount={0} colorTheme={colorTheme}>
                        <PersonIcon color={colorTheme.descriptionColor} />
                    </CartCircle>
                </CartWrapper>
                <CartWrapper onClick={goToCart}>
                    <CartCircle cartCount={cartCount} colorTheme={colorTheme}>
                        <CartIcon color={colorTheme.descriptionColor} />
                    </CartCircle>
                </CartWrapper>
            </SearchWrapper>
        </>
    );
}

export default Search;
