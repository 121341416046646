import React from 'react';

const ArrowIcon = ({ color, width }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_240_54)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.06804 0L5.40002 1.68691L15.3132 12.0158L14.256 13.1174L14.262 13.1109L5.45404 22.2879L7.09683 24C9.53163 21.4644 16.3284 14.3834 18.6 12.0158C16.9128 10.2566 18.558 11.9712 7.06804 0Z"
                      fill={color}/>
            </g>
        </svg>
    )
}

export default ArrowIcon