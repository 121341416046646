import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, PersonStyles } from '../styles/main.css';
import PersonData from '../components/PersonData/PersonData';
import PersonDevice from '../components/PersonDevice/PersonDevice';
import PersonLink from '../components/PersonLink/PersonLink';


const { PersonTitle } = PersonStyles;

const Person = () => {
    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const user = useSelector(state => state.userInfo);

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    mainButton.hide();

    const backButtonHandler = () => {
        navigate('/');
    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);

    return (
        <>
            <Container>
                <PersonTitle colorTheme={theme}>Личный кабинет</PersonTitle>
            </Container>
            <PersonData theme={theme} user={user} />
            <PersonDevice theme={theme} user={user} />
            <PersonLink theme={theme} user={user} />
        </>
    );
}

export default Person;
