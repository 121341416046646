import React from 'react';
import {CategoryStyles, Container} from '../../styles/main.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Item from "../Item";
import {useNavigate} from "react-router-dom";
import Promo from '../Promo';


const { CategoryBgWrapper, CategoryWrapper, CategoryTitleWrapper, CategoryIcon, CategoryTitle } = CategoryStyles;

const Category = ({ icon, title, items, promos, theme, index }) => {
    const navigate = useNavigate();
    const bgColor = index % 2 === 0 ? theme.backgroundColor : theme.cardColor;
    const cardColor = index % 2 === 0 ? theme.cardColor : theme.secondaryCardColor;

    return (
        <CategoryBgWrapper bgColor={bgColor}>
            <Container>
                <CategoryWrapper>
                    <CategoryTitleWrapper>
                        {
                            icon && <CategoryIcon src={icon} alt="icon" />
                        }
                        <CategoryTitle colorTheme={theme}>{title}</CategoryTitle>
                    </CategoryTitleWrapper>
                </CategoryWrapper>
            </Container>

            <Swiper
                slidesPerView={2}
                loop
                className={'defaultSwiper'}
            >
                {promos && promos.map((promo, index) => {
                    return (
                        <SwiperSlide key={index} onClick={() => navigate(`/promo/${promo.id}`)}>
                            <Promo image={promo.image} />
                        </SwiperSlide>
                    )
                })}

                {items && items.map((item, index) => {
                    return (
                        <SwiperSlide key={index} onClick={() => navigate(`/item/${item.id}`)}>
                            <Item image={item.image} price={item.price} discount={item.discount} title={item.title} tags={item.platforms.concat(item.tags)} edition={item.edition} theme={theme} cardColor={cardColor} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </CategoryBgWrapper>
    );
}

export default Category;
