import React, { useEffect } from 'react';
import { Container, PersonStyles } from '../../styles/main.css';
import Link from './Link';
import config from '../../config';

const {PersonHelpText} = PersonStyles;
const PersonLink = ({theme, user}) => {

    return (
        <Container style={{marginTop: '10px', marginBottom: '100px'}}>
            <Link
                theme={theme}
                link="/activeSubscriptions"
                icon={user.activeSubs.length === 0 ? config.STATIC_BASE_URL + '/inactive_sub.png' : config.BASE_URL + user.activeSubs[0].image}
                title="Активная подписка"
                marginTop="0"
            />
            <PersonHelpText colorTheme={theme}>Укажите активные подписки, чтобы бот напоминал их
                продлевать</PersonHelpText>
            <Link
                theme={theme}
                link="/twoFactor"
                icon={config.STATIC_BASE_URL + '/2fa.svg'}
                title="2FA коды"
                marginTop="0"
            />
            <PersonHelpText colorTheme={theme}>Помогут быстрее оформлять ваши заказы</PersonHelpText>
            <Link
                theme={theme}
                link="/favourite"
                icon={config.STATIC_BASE_URL + '/favourite.svg'}
                title="Избранное"
                marginTop="0"
            />
            <Link
                theme={theme}
                link="/buyHistory"
                icon={config.STATIC_BASE_URL + '/buy_list.svg'}
                title="История покупок"
                marginTop="8px"
            />
            <Link
                theme={theme}
                link="https://t.me/trystore_contact"
                icon={config.STATIC_BASE_URL + '/support.svg'}
                title="Обратиться в поддержку"
                marginTop="8px"
            />
            <Link
                theme={theme}
                link="https://t.me/ps_try_store"
                icon={config.STATIC_BASE_URL + '/community.svg'}
                title="Наше сообщество"
                marginTop="8px"
            />
        </Container>
    )
}

export default PersonLink;