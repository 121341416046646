import React, { useEffect, useState } from 'react';
import { Container, PersonStyles } from '../../styles/main.css';
import Device from './Device';
import { put } from '../../lib/api';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../redux-state/reducers/UserInfo';

const { PersonDeviceWrapper, PersonDeviceTitle, PersonHelpText } = PersonStyles;
const PersonDevice = ({ theme, user }) => {

    const [PS5Active, setPS5Active] = useState(false);
    const [PS4Active, setPS4Active] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log(user)
        setPS5Active(user.device === 'ps5');
        setPS4Active(user.device === 'ps4');
    }, [user]);

    const handleActive = (device) => {
        let selectedDevice = null;
        if (device === 'ps5') {
            // !PS5Active && setPS4Active(false);
            PS5Active ? selectedDevice = null : selectedDevice = 'ps5';
        } else if (device === 'ps4') {
            // !PS4Active && setPS5Active(false);
            PS4Active ? selectedDevice = null : selectedDevice = 'ps4';
        }
        put('/users/updateDevice', { token: user.token }, { device: selectedDevice }).then(data => {
            dispatch(updateUserData({...user, device: data.device}));
        })
    }


    return (
        <Container>
            <PersonDeviceTitle colorTheme={theme}>Моя приставка</PersonDeviceTitle>
            <PersonDeviceWrapper colorTheme={theme}>
                <Device theme={theme} active={PS5Active} setActive={() => handleActive('ps5')} device={'PS5'} title="PlayStation 5" />
                <Device theme={theme} active={PS4Active} setActive={() => handleActive('ps4')} device={'PS4'} title="PlayStation 4" />
            </PersonDeviceWrapper>
            <PersonHelpText style={{ marginTop: '14px' }} colorTheme={theme}>Бот будет составлять каталог и подбирать издания игр под вашу PlayStation</PersonHelpText>
        </Container>
    )
}

export default PersonDevice;