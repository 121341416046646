import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DayPicker } from 'react-day-picker';
import '../styles/calendar.css';
import { ActiveSubscriptionStyles } from '../styles/main.css';
import { post } from '../lib/api';
import { updateUserData } from '../redux-state/reducers/UserInfo';


const { ActiveSubscriptionButton, ActiveSubscriptionButtonTitle } = ActiveSubscriptionStyles;


const ActiveSubscriptionDate = () => {
    const [date, setDate] = useState();

    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { subscriptionId } = useParams();

    const user = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    mainButton.hide();

    const backButtonHandler = () => {
        navigate(-1);
    }

    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    }

    const handleSelect = () => {
        post('/users/subs', {token: user.token}, {
            sub_id: subscriptionId,
            date: date.toLocaleDateString('en-GB')
        }).then((res) => {
            dispatch(updateUserData({...user, activeSubs: res.active_subs}));
            navigate('/activeSubscriptions');
        })

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);


    let footer = null;
    if (date) {
        footer = <ActiveSubscriptionButton onClick={handleSelect}>
            <ActiveSubscriptionButtonTitle>Выбрать</ActiveSubscriptionButtonTitle>
        </ActiveSubscriptionButton>
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DayPicker
                mode="single"
                selected={date}
                onSelect={setDate}
                footer={footer}
                fromDate={new Date()}
                ISOWeek
                locale={locale}
                styles={{
                    day: {
                        color: theme.descriptionColor
                    },
                    month: {
                        color: theme.descriptionColor
                    }
                }}
            />
        </div>
    );
}

export default ActiveSubscriptionDate;
