import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useSelector} from "react-redux";
import {useNavigate } from "react-router-dom";
import Header from "../components/Cart/Header";
import {CartStyles} from "../styles/main.css";
import Details from "../components/Cart/Details";
import {post} from "../lib/api";

const { CartWrapper } = CartStyles;

const Cart = () => {
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    backButton.show();
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });
    const mainButton = tg.MainButton;
    const cartItems = cart.items.concat(cart.subscriptions);

    const [activeTab, setActiveTab] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [accountEmailValue, setAccountEmailValue] = useState('');
    const [accountPasswordValue, setAccountPasswordValue] = useState('');
    const [checkoutEmailValue, setCheckoutEmailValue] = useState('');
    const [rememberData, setRememberData] = useState(false);

    const [discount, setDiscount] = useState(user.discount);
    const [promoValue, setPromoValue] = useState('');

    useEffect(() => {
        if (user.accountEmail && user.accountEmail.length > 0) {
            setAccountEmailValue(user.accountEmail);
        }
        if (user.accountPassword && user.accountPassword.length > 0) {
            setAccountPasswordValue(user.accountPassword);
        }
        if (user.checkoutEmail && user.checkoutEmail.length > 0) {
            setCheckoutEmailValue(user.checkoutEmail);
        }
    }, []);

    const completeOrder = () => {
        mainButton.showProgress();
        post('/users/order', {token: user.token}, {cart: cart, account_email: activeTab === 0 ? accountEmailValue : null, account_password: activeTab === 0 ? accountPasswordValue : null, checkout_email: checkoutEmailValue, is_remember: rememberData, promo: user.discount > 0 ? user.promoCode : null}).then((res) => {
            tg.openLink(res.link);
        }).catch((err) => {
            console.log(err);
        })
        setTimeout(() => {
            tg.disableClosingConfirmation();
            tg.close();
        }, 5000);
    }

    const setEnableMainButton = (enable) => {
        if (enable && cartItems.length > 0) {
            mainButton.color = "#435FFF"
            mainButton.enable();
        } else {
            mainButton.color = "#D9D9D9"
            mainButton.disable();
        }
    }

    useEffect(() => {
        post('/users/updateCart', {token: user.token}, {cart: cart}).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [cart]);

    useEffect(() => {
        if (cartItems.length > 0) {
            setTotalAmount(cartItems.reduce((acc, item) => {
                let price = acc + parseInt(item.price);
                if (user.discount > 0) {
                    // price = price - (price * (user.discount / 100));
                    price = price * (100 - user.discount) / 100;
                }
                return Math.round(price)
            }, 0));
        } else {
            setTotalAmount(0);
        }
    }, [cart, user]);

    useEffect(() => {
        if (totalAmount === 0) {
            mainButton.color = "#D9D9D9"
            mainButton.disable();

        }
    }, [totalAmount]);

    useEffect(() => {
        mainButton.text = "Оформить заказ"
        mainButton.onClick(completeOrder);
        mainButton.show();
        return () => {
            mainButton.offClick(completeOrder);
        }
    }, [cart, accountEmailValue, accountPasswordValue, checkoutEmailValue, rememberData, user, activeTab, totalAmount]);

    return (
        <>
            <CartWrapper>
                <Header cart={cart} totalAmount={totalAmount} theme={theme} />
                <Details
                    setEnable={setEnableMainButton}
                    theme={theme}
                    accountEmailValue={accountEmailValue}
                    accountPasswordValue={accountPasswordValue}
                    checkoutEmailValue={checkoutEmailValue}
                    rememberData={rememberData}
                    setAccountEmailValue={setAccountEmailValue}
                    setAccountPasswordValue={setAccountPasswordValue}
                    setCheckoutEmailValue={setCheckoutEmailValue}
                    setRememberData={setRememberData}
                    discount={discount}
                    setDiscount={setDiscount}
                    promoValue={promoValue}
                    setPromoValue={setPromoValue}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </CartWrapper>
        </>
    );
}

export default Cart;
