import React, { useState } from 'react';
import { CartStyles } from '../../styles/main.css';


const { CartPromoInputWrapper, CartPromoInput, CartPromoInputButton } = CartStyles;

const TwoFAInput = ({ theme, value, setValue, handleClick }) => {

    return (
        <>
            <CartPromoInputWrapper colorTheme={theme}>
                <CartPromoInput colorTheme={theme} bgColor={theme.cardColor} type="text" placeholder="2FA код" value={value} onChange={(e) => setValue(e.target.value)} />
                {
                    value.length > 0 && <CartPromoInputButton onClick={handleClick} colorTheme={theme}>Добавить</CartPromoInputButton>
                }
            </CartPromoInputWrapper>

        </>
    )
}

export default TwoFAInput