import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, FiltersPageStyles } from "../styles/main.css";
import Radio from "../components/Cart/Radio";
import { get } from "../lib/api";
import CloseIcon from '../components/Icons/CloseIcon';

const { FiltersWrapper, FiltersTitle, FiltersCloseBtn, FiltersItemsWrapper, FiltersItemWrapper, FiltersItemTitle, FiltersOptionsWrapper, FiltersSelect } = FiltersPageStyles;

const Filters = () => {
    const { theme } = useTheme();

    const user = useSelector(state => state.userInfo);
    const { state } = useLocation();
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    backButton.show();
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });

    const mainButton = tg.MainButton;
    mainButton.text = "Применить фильтры"
    mainButton.enable();

    const [ platforms, setPlatforms ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ prices, setPrices ] = useState([500, 1000, 2000]);
    const [ platformsActiveIndex, setPlatformsActiveIndex ] = useState(null);
    const [ categoriesActiveIndex, setCategoriesActiveIndex ] = useState(null);
    const [ priceActiveIndex, setPriceActiveIndex ] = useState(null);

    const setFilters = () => {
        navigate(state.prevPage, {state: {searchValue: state.searchValue, platform: platforms[platformsActiveIndex], category: categories[categoriesActiveIndex], price: prices[priceActiveIndex]}});
    }

    const goToBack = () => {
        navigate(state.prevPage, {state: {searchValue: state.searchValue, platform: state.platform, category: state.category, price: state.price}});
    }

    useEffect(() => {
        get('/items/filters', {token: user.token}).then((res) => {
            setPlatforms(res.platforms);
            setCategories(res.categories);
            if (state?.platform) {
                setPlatformsActiveIndex(res.platforms.indexOf(state.platform));
            }
            if (state?.category) {
                setCategoriesActiveIndex(res.categories.indexOf(state.category));
            }
            if (state?.price) {
                setPriceActiveIndex(prices.indexOf(state.price));
            }
        });
    }, []);

    useEffect(() => {
        mainButton.onClick(setFilters);
        mainButton.color = "#435FFF";
        mainButton.textColor = "#FFFFFF";
        mainButton.show();
        return () => {
            mainButton.offClick(setFilters);
        }
    }, [platformsActiveIndex, categoriesActiveIndex, priceActiveIndex]);

    const handlePlatforms = (index) => {
        if (index === platformsActiveIndex) {
            setPlatformsActiveIndex(null);
        } else {
            setPlatformsActiveIndex(index);
        }
    }

    const platformActive = (index) => {
        return platformsActiveIndex === index;
    }

    const handleCategories = (index) => {
        if (index === categoriesActiveIndex) {
            setCategoriesActiveIndex(null);
        } else {
            setCategoriesActiveIndex(index);
        }
    }

    const handlePrices = (index) => {
        if (index === priceActiveIndex) {
            setPriceActiveIndex(null);
        } else {
            setPriceActiveIndex(index);
        }
    }

    const priceActive = (index) => {
        return priceActiveIndex === index;
    }

    return (
        <>
            <Container>
                <FiltersWrapper colorTheme={theme}>
                    <FiltersTitle colorTheme={theme}>Фильтры</FiltersTitle>
                    <FiltersCloseBtn colorTheme={theme} onClick={goToBack}>
                        <CloseIcon color={theme.descriptionColor} />
                    </FiltersCloseBtn>
                </FiltersWrapper>
                <FiltersItemsWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Платформа</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {platforms && platforms.map((platform, index) => {
                                return <Radio theme={theme} title={platform} active={platformActive(index)} handleActive={() => {handlePlatforms(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Категория товара</FiltersItemTitle>
                        {/*<FiltersOptionsWrapper>*/}
                        {/*    {categories && categories.map((category, index) => {*/}
                        {/*        return <Radio theme={theme} title={category} active={categoryActive(index)} handleActive={() => {handleCategories(index)} } />*/}
                        {/*    })}*/}
                        {/*</FiltersOptionsWrapper>*/}
                        <FiltersSelect colorTheme={theme} onChange={(e) => {handleCategories(e.target.value)}} value={categoriesActiveIndex}>
                            <option value={null}>Все</option>
                            {categories && categories.map((category, index) => {
                                return <option value={index}>{category}</option>
                            })}
                        </FiltersSelect>
                    </FiltersItemWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Цена</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {prices && prices.map((price, index) => {
                                return <Radio theme={theme} title={`до ${price} ₽`} active={priceActive(index)} handleActive={() => {handlePrices(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                </FiltersItemsWrapper>
            </Container>
        </>
    );
}

export default Filters;
