import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';

import Item from './Item';
import {get} from "../../lib/api";
import {useSelector} from "react-redux";
import config from "../../config";
import {useNavigate} from "react-router-dom";
import Promo from './Promo';

const BestItems = () => {
    const [ items, setItems ] = useState([]);
    const user = useSelector(state => state.userInfo);
    const [swiper, setSwiper] = useState(null);

    const navigate = useNavigate();

    const goToSwiper = () => {
        console.log(items.length)
        swiper.slideTo((items.length / 2) || 1)
    }

    useEffect(() => {
        get('/items/getBestItems', {token: user.token}).then((res) => {
            let multipleItems = []
            for (let i = 0; i < 10; i++) {
                multipleItems.push(...res.items);
            }
            setItems(multipleItems);
            // setItems(res.items);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        if (swiper) {
            goToSwiper();
        }
    }, [items]);

    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            spaceBetween={20}
            onSwiper={setSwiper}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1.2,
                slideShadows: false,
            }}
            modules={[EffectCoverflow]}
            className="swiper_container"
        >
            {items && items.map((item, index) => (
                item.type === 'edition' ?
                <SwiperSlide key={index} onClick={() => navigate(`/item/${item.id}`)}>
                    <Item image={item.image} discount={item.discount} price={item.price} platforms={item.platforms} tags={item.tags}/>
                </SwiperSlide> :
                    <SwiperSlide key={index} onClick={() => navigate(`/promo/${item.id}`)}>
                        <Promo image={item.image} />
                    </SwiperSlide>
            ))}
        </Swiper>
    );
}


export default BestItems;