import React from 'react';
import { PersonStyles } from '../../styles/main.css';

const { PersonDataInput } = PersonStyles;

const Input = ({ value, setValue, placeholder, theme, onBlur, type, tabIndex }) => {
    const handleInput = (e) => {
        setValue(e.target.value);
    }

    return (
        <PersonDataInput colorTheme={theme} value={value} onChange={handleInput} enterKeyHint={"done"}
                         placeholder={placeholder} onBlur={onBlur} type={type} tabIndex={tabIndex}
                         onKeyDown={(e) => {
                             if (e.key === 'Enter') {
                                 (e.target).blur();
                             }
                         }}
        />
    );
}

export default Input