
const PersonIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip227_15)">
                <path id="Vector" d="M18 20C18 18.4 17.36 16.88 16.24 15.75C15.11 14.63 13.59 14 12 14C10.4 14 8.88 14.63 7.75 15.75C6.63 16.88 6 18.4 6 20" stroke={color} strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"/>
                <path id="Vector" d="M12 14C9.79 14 8 12.2 8 10C8 7.79 9.79 6 12 6C14.2 6 16 7.79 16 10C16 12.2 14.2 14 12 14Z" stroke={color} strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round"/>
                <path id="Vector" d="M12 22C6.47 22 2 17.52 2 12C2 6.47 6.47 2 12 2C17.52 2 22 6.47 22 12C22 17.52 17.52 22 12 22Z" stroke={color} strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}

export default PersonIcon;