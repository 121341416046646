import React from 'react';
import { PersonStyles } from '../../styles/main.css';
import PS5 from '../Icons/PS5';
import PS4 from '../Icons/PS4';

const {PersonDeviceItem, PersonDeviceItemTitle} = PersonStyles;
const Device = ({theme, active, setActive, device, title}) => {

    return (
        <PersonDeviceItem isActive={active} colorTheme={theme} onClick={setActive}>
            {
                device === 'PS5' ?
                    <PS5 color={active ? '#435FFF' : theme.descriptionColor}/>
                    :
                    <PS4 color={active ? '#435FFF' : theme.descriptionColor}/>
            }
            <PersonDeviceItemTitle colorTheme={theme} isActive={active}>{title}</PersonDeviceItemTitle>
        </PersonDeviceItem>
    )
}

export default Device;