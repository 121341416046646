import React from 'react';
import {CartStyles} from '../../styles/main.css';

const { CartOrderRadioWrapper, CartOrderRadioCircle, CartOrderRadio, CartOrderRadioTitle } = CartStyles;

const Radio = ({ title, active, handleActive, theme, fontSize, marginTop }) => {
    return (
        <>
            <CartOrderRadioWrapper style={{ marginTop: marginTop ? marginTop : null }}>
                <CartOrderRadioCircle>
                    <CartOrderRadio isActive={active} onClick={handleActive}></CartOrderRadio>
                </CartOrderRadioCircle>
                <CartOrderRadioTitle colorTheme={theme} fontSize={fontSize} >{title}</CartOrderRadioTitle>
            </CartOrderRadioWrapper>

        </>
    );
}

export default Radio;
