import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActiveSubscriptionStyles, Container, PersonStyles, TwoFactorStyles } from '../styles/main.css';
import PersonLinkInfo from '../components/PersonLinkInfo/PersonLinkInfo';
import config from '../config';
import Instruction from '../components/TwoFA/Instruction';
import TwoFAItem from '../components/TwoFA/TwoFAItem';
import TwoFAInput from '../components/TwoFA/TwoFAInput';
import { get, post } from '../lib/api';


const {PersonHelpText} = PersonStyles;
const {ActiveSubscriptionButton, ActiveSubscriptionButtonTitle} = ActiveSubscriptionStyles;
const {TwoFactorItems} = TwoFactorStyles;

const TwoFA = () => {

    const [placeholderActive, setPlaceholderActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [codes, setCodes] = useState([]);

    const tg = window.Telegram.WebApp;
    const {theme} = useTheme();
    const navigate = useNavigate();

    const user = useSelector(state => state.userInfo);

    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    mainButton.hide();

    const backButtonHandler = () => {
        navigate(-1);
    }

    const buttonHandler = () => {
        setPlaceholderActive(true);
    }

    const addCode = (code) => {
        post('/users/twoFa', {token: user.token}, {code}).then(data => {
            setCodes(data.result);
        })
        setPlaceholderActive(false);
        setInputValue('');
    }

    useEffect(() => {
        get('/users/twoFa', {token: user.token}).then(data => {
            setCodes(data.result);
        })
    }, []);

    useEffect(() => {
        backButton.show();
        backButton.onClick(backButtonHandler);

        return () => {
            backButton.hide();
            backButton.offClick(backButtonHandler);
        }
    }, []);

    return (
        <>
            <PersonLinkInfo
                title="2FA коды"
                icon={config.STATIC_BASE_URL + '/lock.svg'}
                textList={[
                    'Напишите 2FA коды от своего аккаунта PlayStation.',
                    'Бот использует их, чтобы быстрее оформлять ваши заказы.'
                ]}
                theme={theme}
            />
            <Container>
                <PersonHelpText colorTheme={theme}>
                    Чтобы у аккаунта появились 2FA коды, включите в нём двухфакторную аутентификацию.
                </PersonHelpText>
                <Instruction theme={theme}/>
                <TwoFactorItems>
                    {
                        codes.map((code, index) => <TwoFAItem key={index} theme={theme} codeId={code.id}
                                                              code={code.code} isActive={code.is_active} user={user}
                                                              setCodes={setCodes}/>)
                    }
                </TwoFactorItems>
                {
                    placeholderActive ?
                        <TwoFAInput theme={theme} value={inputValue} setValue={setInputValue}
                                    handleClick={() => addCode(inputValue)}/>
                        :
                        <ActiveSubscriptionButton>
                            <ActiveSubscriptionButtonTitle onClick={buttonHandler}>Добавить
                                новый</ActiveSubscriptionButtonTitle>
                        </ActiveSubscriptionButton>
                }
            </Container>

        </>
    );
}

export default TwoFA;
